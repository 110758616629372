import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    convertCreativeToDocumentDto,
    stringifyDesignDocument,
    toUpdateCreativeDtoV2s,
    toUpdateDesignDtos,
    toUpdateElementDtos,
    toUpdateVersionDtos
} from '@creative/serialization';
import { serializeVersionProperties } from '@creative/serialization/versions/version-serializer';
import { creativeToChecksum, updateChecksumOfCreatives } from '@creative/utils';
import { deserializeCreativeset, removeGraphQlTypename } from '@data/deserialization/creativeset';
import { convertDesignApiCreativesetToOldModel } from '@data/deserialization/design-api/design-api-creativeset';
import { DELETE_DESIGNS, DELETE_SIZES } from '@data/graphql';
import {
    BEGIN_CREATIVE_GENERATION,
    UPDATE_APPROVAL_STATUS,
    UPDATE_CREATIVES,
    UpdateCreativeStatusVariables
} from '@data/graphql/creative.queries';
import { GET_FOLDER_ID, UPDATE_CREATIVESET_STATE_ID } from '@data/graphql/creativeset.queries';
import { CreativeSetDto } from '@domain/api/generated/design-api';
import {
    CreateDesignsAndVersionsDto,
    CreativeSetDtoV2,
    UpdateDesignsAndVersionsDto
} from '@domain/api/generated/sapi';
import { IBrand } from '@domain/brand';
import { IDesign, ISerializedDesign } from '@domain/creativeset';
import {
    ApprovalStatus,
    CreativeCreate,
    ICreative,
    ICreativeDto
} from '@domain/creativeset/creative/creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { IElement } from '@domain/creativeset/element';
import { CreativeSize } from '@domain/creativeset/size';
import { ISerializedVersion, IVersion } from '@domain/creativeset/version';
import { IFontFamily } from '@domain/font-families';
import { isKnownErrorResponse } from '@studio/domain/api/error';
import { HttpRequestObservable } from '@studio/domain/api/error.types';
import { StudioFeatureFlags } from '@studio/domain/feature-flags/studio.ff.types';
import { FeatureService } from '../services/feature/feature.service';
import { IShowcaseBrand } from '@studio/domain/showcase';
import { EventLoggerService, VersionValidationFailEvent } from '@studio/monitoring/events';
import { FontFamiliesService } from '@studio/common/font-families';
import { FontFamiliesDataService } from '@studio/common/font-families/font-families.data.service';
import { distinctArrayById } from '@studio/utils/array';
import { cloneDeep } from '@studio/utils/clone';
import { handleError } from '@studio/utils/errors/errors';
import { uuidv4 } from '@studio/utils/id';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, firstValueFrom, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorsRedirectionService } from '../services/errors-redirection.service';
import { fixWidgetVersionProperties } from '../utils/widget-version-properties';
import { mergeVersions } from '../versions/versions.utils';
import { validateVersions } from '../versions/versions.validation';
import { ICreateDesignInput, ICreateDesignInputType, IPreparedDesigns } from './creativeset-mutations';

@Injectable({ providedIn: 'root' })
export class CreativesetDataService {
    private apolloClient = inject(Apollo);
    private featureFlagService = inject(FFFeatureFlagsService);
    private featureService = inject(FeatureService);
    private fontFamiliesDataService = inject(FontFamiliesDataService);
    private fontFamiliesService = inject(FontFamiliesService);
    private httpClient = inject(HttpClient);
    private errorsRedirectionService = inject(ErrorsRedirectionService);
    private sentinelService = inject(SentinelService);
    private eventLoggerService = inject(EventLoggerService);
    private logger = new Logger('CreativesetDataService');
    brand: Readonly<IBrand | IShowcaseBrand>; // will be set once and never changes, hence not really async

    private _creativeset$ = new BehaviorSubject<ICreativeset>(this.creativeset);
    creativeset$ = this._creativeset$.asObservable().pipe(
        tap(creativeset => {
            this._creativeset = creativeset;
        })
    );

    sizes$ = this.creativeset$.pipe(
        filter(Boolean),
        map(({ sizes }) => sizes)
    );

    private _creativeset: ICreativeset;
    get creativeset(): ICreativeset {
        return this._creativeset;
    }

    private get API_SAPI_BASE_ROUTE(): `${string}/creative-sets` {
        return `${environment.origins.sapi}/creative-sets` as const;
    }

    private getApiBaseRoute(isDapiEnabled: boolean): `${string}/creative-sets` {
        if (isDapiEnabled) {
            return `${environment.origins.designApi}/creative-sets`;
        }

        return `${environment.origins.sapi}/creative-sets` as const;
    }

    setCreativeset(creativeset: ICreativeset): void {
        this._creativeset$.next(creativeset);
    }

    setBrand(brand: IBrand | IShowcaseBrand): void {
        this.brand = brand;
    }

    /**
     * This emits to the creativeset$ observable for cases where
     * the creativeset is mutated outside of the mutation stream.
     * This function will be redundant when all mutations happens
     * in the streams instead.
     */
    creativesetChanged(): void {
        this._creativeset$.next(this.creativeset);
    }

    syncFonts(fontFamilies?: IFontFamily[]): Observable<IFontFamily[]> {
        this.logger.debug('Syncing fonts');
        const fonts = fontFamilies ?? this.fontFamiliesDataService.creativesetFontFamilies;
        this.fontFamiliesService.loadFontFamilies();
        return this.fontFamiliesService.fontFamilies$.pipe(
            take(1),
            tap(() => this.fontFamiliesService.setFontFamiliesOfCreativeSet(fonts))
        );
    }

    getAccountSlug(): string {
        return (this.brand as IBrand).accountSlug || '';
    }

    getBrandSlug(): string {
        return (this.brand as IBrand).slug || '';
    }

    getCreativesFromVersionsOrAll(versions: IVersion[]): ICreative[] {
        if (!versions.length) {
            return [...this.creativeset.creatives];
        }

        return [...this.creativeset.creatives].filter(creative =>
            versions.find(v => v.id === creative.version.id)
        );
    }

    getCreativeset(creativesetId: string): Observable<ICreativeset> {
        return this.featureFlagService.isEnabled$(StudioFeatureFlags.StudioClientDAPIEnabled).pipe(
            catchError(err => {
                this.logger.warn(err);
                return of(false);
            }),
            switchMap(isDapiEnabled => {
                isDapiEnabled = this.featureService.isDesignApiFeatureEnabled() ? true : isDapiEnabled;
                return this.httpClient
                    .get<CreativeSetDtoV2>(`${this.getApiBaseRoute(isDapiEnabled)}/${creativesetId}`)
                    .pipe(map(creativesetDto => ({ creativesetDto, isDapiEnabled })));
            }),
            map(({ creativesetDto, isDapiEnabled }) => {
                if (isDapiEnabled) {
                    return convertDesignApiCreativesetToOldModel(
                        creativesetDto as unknown as CreativeSetDto
                    );
                }

                return deserializeCreativeset(creativesetDto);
            }),
            tap(creativeset => this.setCreativeset(creativeset)),
            catchError(error => {
                handleError('Could not get creativeset', { contexts: { originalError: error } });
                this.errorsRedirectionService.handleErrorRedirection(error);
                return of();
            })
        );
    }

    async validate(payloadBody: UpdateDesignsAndVersionsDto): Promise<boolean> {
        const route = `${this.getApiRoute()}/validate`;

        try {
            await firstValueFrom(this.httpClient.put(route, payloadBody));
            return true;
        } catch {
            return false;
        }
    }

    getFolderId(creativesetId: string): Observable<string> {
        return this.apolloClient
            .query({
                query: GET_FOLDER_ID,
                variables: {
                    id: creativesetId
                }
            })
            .pipe(map(response => cloneDeep(response.data.creativeset.folderId)));
    }

    setApprovalStatus(
        approvalStatus: ApprovalStatus,
        creatives: ICreative[],
        creativesetId: string
    ): Observable<ICreative[]> {
        const creativesToUpdate = creatives.map(creative => ({
            id: creative.id,
            approvalStatus: approvalStatus
        }));

        return this.apolloClient
            .mutate({
                mutation: UPDATE_APPROVAL_STATUS,
                variables: {
                    creativesetId: creativesetId,
                    creatives: creativesToUpdate
                }
            })
            .pipe(
                map(({ data }) => {
                    const updatedCreatives = data!.setApprovalStatusOnCreatives;

                    creatives.forEach(creative => {
                        const updatedCreative = updatedCreatives.find(uc => uc.id === creative.id);

                        if (!updatedCreative) {
                            return;
                        }

                        creative = {
                            ...creative,
                            approvalStatus: updatedCreative.approvalStatus || ApprovalStatus.None
                        };
                    });

                    return creatives;
                }),
                tap(() => this.creativesetChanged())
            );
    }

    updateCreatives(creatives: ICreative[]): Observable<ICreative[]> {
        const creativeUpdateList = creatives.reduce<UpdateCreativeStatusVariables['creatives']>(
            (acc, creative) => {
                creative.checksum = creativeToChecksum(
                    creative,
                    creative.version,
                    this.creativeset.stateId
                );
                return [
                    ...acc,
                    {
                        id: creative.id,
                        targetUrl: creative.targetUrl?.trim(),
                        checksum: creative.checksum,
                        approvalStatus: creative.approvalStatus
                    }
                ];
            },
            []
        );

        return this.apolloClient
            .mutate({
                mutation: UPDATE_CREATIVES,
                variables: {
                    creatives: creativeUpdateList
                }
            })
            .pipe(
                map(({ data }) => {
                    const updatedCreatives = data!.updateCreatives;

                    creatives.forEach(creative => {
                        const updatedCreative = updatedCreatives.find(uc => uc.id === creative.id);

                        if (!updatedCreative) {
                            return;
                        }

                        creative = {
                            ...creative,
                            ...updatedCreative
                        };
                    });

                    return creatives;
                })
            );
    }

    createDesignsWithExistingSize(
        creativeInputs: ICreateDesignInputType[]
    ): HttpRequestObservable<ICreativeset | undefined> {
        const newCreativesArray: CreativeCreate[] = [];

        for (const { size, versions: creativesInputVersions } of creativeInputs) {
            const sizeId = size.id;
            const versions = this.creativeset.versions.filter(
                ({ id }) => !!creativesInputVersions.find(v => v.id === id)
            );
            const creatives = this.creativeset.creatives.filter(
                creative =>
                    creative.size.id === sizeId &&
                    creativesInputVersions.some(v => v.id === creative.version.id)
            );

            for (const creative of creatives) {
                const version = versions.find(v => v.id === creative.version.id)!;

                const dirtyDesign = creativeInputs.find(
                    input => input.size.id === creative.size?.id
                )?.design;

                const dirtyCreative = {
                    ...cloneDeep(creative),
                    version,
                    ...(dirtyDesign
                        ? {
                              design: {
                                  id: dirtyDesign.id ?? '',
                                  name: dirtyDesign.name ?? '',
                                  elements: dirtyDesign.elements,
                                  document: dirtyDesign.document,
                                  hasHeavyVideo: dirtyDesign.hasHeavyVideo
                              }
                          }
                        : {})
                };

                const newChecksum = creativeToChecksum(
                    dirtyCreative,
                    version,
                    this.creativeset.stateId
                );
                if (dirtyCreative.checksum !== newChecksum) {
                    dirtyCreative.checksum = newChecksum;
                    newCreativesArray.push({
                        id: dirtyCreative.id,
                        checksum: newChecksum
                    });
                }
            }
        }

        if (!newCreativesArray.length) {
            return of(undefined);
        }

        const allVersions = creativeInputs.flatMap(({ versions }) => versions);

        const allElements = creativeInputs
            .flatMap(({ design }) => design)
            .flatMap(({ elements }) => elements);

        const allDistinctElements = distinctArrayById(allElements);

        let sanitizedVersions = this.removeDanglingVersionProperties(allDistinctElements, allVersions);
        sanitizedVersions = fixWidgetVersionProperties(sanitizedVersions, this.creativeset.creatives);

        const creatives = newCreativesArray;
        const versions = distinctArrayById(
            toUpdateVersionDtos(sanitizedVersions, this.sentinelService)
        );
        const elements = toUpdateElementDtos(allDistinctElements);
        const designs = creativeInputs.flatMap(({ design, size }) => ({
            document: convertCreativeToDocumentDto(design.document),
            sizeId: size.id
        }));

        const body: CreateDesignsAndVersionsDto = {
            creatives,
            versions,
            elements,
            designs
        };

        return this.httpClient
            .post<CreativeSetDtoV2>(
                `${this.API_SAPI_BASE_ROUTE}/${this.creativeset.id}/designs-and-versions`,
                body,
                { observe: 'response' }
            )
            .pipe(this.handleDesignsAndVersionsRequests);
    }

    private handleDesignsAndVersionsRequests = (
        responseObservable: Observable<HttpResponse<CreativeSetDtoV2>>
    ): HttpRequestObservable<ICreativeset | undefined> => {
        return responseObservable.pipe(
            switchMap((response: HttpResponse<CreativeSetDtoV2>) => {
                const creativeSet = deserializeCreativeset(response.body!);
                this.setCreativeset(creativeSet);
                return of(creativeSet);
            }),
            catchError((errorResponse: unknown) => {
                if (!isKnownErrorResponse(errorResponse)) {
                    throw new Error(`Unknown HTTP error occurred. ${errorResponse}`);
                }
                return of(errorResponse);
            })
        );
    };

    private getApiRoute(): string {
        return `${this.API_SAPI_BASE_ROUTE}/${this.creativeset.id}/designs-and-versions`;
    }

    updateDesignsInCreativeset(
        allVersions: IVersion[],
        updatedDesigns: IDesign[],
        updatedVersions: IVersion[],
        defaultVersion: IVersion
    ): HttpRequestObservable<ICreativeset | undefined> {
        if (updatedDesigns.length === 0) {
            return of(undefined);
        }

        const body = this.prepareDesignsAndVersionsUpdate(
            allVersions,
            updatedDesigns,
            updatedVersions,
            defaultVersion
        );

        if (!body.creatives.length) {
            this.logger.debug('No creatives to update during updateDesignsInCreativeset.');
            return of(undefined);
        }

        return this.httpClient
            .put<CreativeSetDtoV2>(
                `${this.API_SAPI_BASE_ROUTE}/${this.creativeset.id}/designs-and-versions`,
                body,
                { observe: 'response' }
            )
            .pipe(this.handleDesignsAndVersionsRequests);
    }

    deleteDesignsInCreativeset(ids: string[]): Observable<string[]> {
        return this.apolloClient
            .mutate({
                mutation: DELETE_DESIGNS,
                variables: {
                    designIds: ids,
                    creativesetId: this.creativeset.id
                }
            })
            .pipe(
                map(({ data }) => {
                    const designsToClear = this.creativeset.designs.filter(design =>
                        data!.deleteDesigns.ids.includes(design.id)
                    );

                    const propertiesIdsToRemove = this.deleteDesignsFromClient(designsToClear);
                    return propertiesIdsToRemove;
                }),
                tap(() => this.creativesetChanged())
            );
    }

    deleteSizesInCreativeset(sizes: CreativeSize[]): Observable<ICreativeset> {
        return this.apolloClient
            .mutate({
                mutation: DELETE_SIZES,
                variables: {
                    creativesetId: this.creativeset.id,
                    ids: sizes.map(s => s.id)
                }
            })
            .pipe(
                map(({ data, errors }) => {
                    if (!data || errors) {
                        handleError('Could not delete sizes in creativeset', {
                            contexts: { originalError: errors }
                        });
                    }
                }),
                switchMap(() => this.getCreativeset(this.creativeset.id))
            );
    }

    updateStateId(): Observable<void> {
        const stateId = uuidv4();
        return this.apolloClient
            .mutate({
                mutation: UPDATE_CREATIVESET_STATE_ID,
                variables: {
                    creativesetId: this.creativeset.id,
                    stateId: stateId,
                    creatives: this.creativeset.creatives.map(creative => ({
                        id: creative.id,
                        checksum: creativeToChecksum(creative, creative.version, stateId)
                    }))
                }
            })
            .pipe(
                switchMap(() => this.getCreativeset(this.creativeset.id)),
                map(() => void 0)
            );
    }

    publishChanges(creatives: ICreative[]): Observable<{
        creatives: Pick<ICreativeDto, 'id' | 'checksum'>[];
        failures: Pick<ICreativeDto, 'id' | 'checksum'>[];
    }> {
        const creativeIds = creatives.map(creative => creative.id);

        return this.apolloClient
            .mutate({
                mutation: BEGIN_CREATIVE_GENERATION,
                variables: {
                    creativeIds,
                    creativesetId: this.creativeset.id
                }
            })
            .pipe(
                map(({ data }) => {
                    if (!data?.beginCreativeGeneration) {
                        throw new Error('No beginCreativeGeneration data');
                    }

                    return data.beginCreativeGeneration;
                })
            );
    }

    private synchronizeCreativeDesigns(creatives: ICreative[], designs: ICreateDesignInput[]): void {
        for (const creative of creatives) {
            if (!creative.design) {
                continue;
            }

            const design = designs.find(({ id }) => creative.design!.id === id);
            if (!design) {
                continue;
            }

            for (const element of design.elements) {
                if (!creative.design.elements.some(({ id }) => id === element.id)) {
                    creative.design.elements.push(element);
                }
            }

            for (const element of creative.design.elements) {
                const elementFromDesign = design.elements.find(({ id }) => id === element.id);
                if (!elementFromDesign) {
                    creative.design.elements = creative.design.elements.filter(
                        ({ id }) => id !== element.id
                    );
                } else {
                    // Update the name with the current value. Used when no others modifications were done
                    element.name = elementFromDesign.name;
                    element.properties = elementFromDesign.properties;
                }
            }

            creative.design.document = design.document;
        }
    }

    prepareDesignsAndVersionsUpdate(
        allVersions: IVersion[],
        updatedDesigns: IDesign[],
        updatedVersions: IVersion[],
        defaultVersion: IVersion
    ): UpdateDesignsAndVersionsDto {
        const { creatives, stateId } = this.creativeset;
        const clonedCreatives = cloneDeep(creatives);
        const clonedDesigns = cloneDeep(updatedDesigns);

        this.synchronizeCreativeDesigns(clonedCreatives, clonedDesigns);

        const dirtyCreatives = clonedCreatives
            .filter(creative => creative.design)
            .filter(
                creative =>
                    clonedDesigns.find(({ id }) => creative.design!.id === id) ||
                    updatedVersions.find(({ id }) => creative.version.id === id)
            );

        const updatedCreatives = updateChecksumOfCreatives(dirtyCreatives, allVersions, stateId);

        const isDefaultVersionMissing = !updatedVersions.some(({ id }) => id === defaultVersion.id);
        if (isDefaultVersionMissing) {
            updatedVersions.push(defaultVersion);
        }

        // All elements from updating designs should be included
        const elements = distinctArrayById(clonedDesigns.flatMap(design => design.elements));

        updatedVersions = this.removeDanglingVersionProperties(elements, updatedVersions);
        updatedVersions = fixWidgetVersionProperties(updatedVersions, this.creativeset.creatives);

        try {
            validateVersions(updatedVersions);
        } catch (e) {
            if (e instanceof VersionValidationFailEvent) {
                this.eventLoggerService.log(e);
            }
        }

        return {
            creatives: toUpdateCreativeDtoV2s(updatedCreatives),
            versions: toUpdateVersionDtos(updatedVersions),
            designs: toUpdateDesignDtos(clonedDesigns),
            elements: toUpdateElementDtos(elements)
        };
    }

    prepareDesignsForSave(
        allVersions: IVersion[],
        designInputs: ICreateDesignInput[],
        dirtyVersions: IVersion[],
        defaultVersion: IVersion
    ): IPreparedDesigns {
        const { creatives, stateId, elements } = this.creativeset;

        const partialCreativeset: Pick<ICreativeset, 'designs' | 'elements' | 'creatives'> = {
            creatives: cloneDeep(creatives),
            designs: designInputs.map(design => {
                return {
                    id: design.id!,
                    name: design.name,
                    document: design.document,
                    hasHeavyVideo: design.hasHeavyVideo,
                    elements: cloneDeep(design.elements)
                };
            }),
            elements: elements
        };

        for (const creative of partialCreativeset.creatives) {
            if (creative.design) {
                const design = designInputs.find(d => creative.design!.id === d.id)!;
                if (!design) {
                    continue;
                }

                design.elements.forEach(element => {
                    if (!creative.design!.elements.find(e => e.id === element.id)) {
                        creative.design!.elements.push(element);
                    }
                });

                creative.design.elements.forEach(element => {
                    const elementFromDesign = design.elements.find(e => e.id === element.id);
                    if (!elementFromDesign) {
                        creative.design!.elements = creative.design!.elements.filter(
                            el => el.id !== element.id
                        );
                    } else {
                        // Update the name with the current value. Used when no others modifications were done
                        element.name = elementFromDesign.name;
                        element.properties = elementFromDesign.properties;
                    }
                });

                creative.design.document = design.document;
            }
        }

        let dirtyCreatives = partialCreativeset.creatives
            .filter(creative => creative.design)
            .filter(
                creative =>
                    designInputs.find(design => creative.design!.id === design.id) ||
                    dirtyVersions.find(version => creative.version.id === version.id)
            );

        dirtyCreatives = updateChecksumOfCreatives(dirtyCreatives, allVersions, stateId);

        if (!dirtyVersions.find(v => v.id === defaultVersion.id)) {
            dirtyVersions.push(defaultVersion);
        }

        const updatedDesigns = designInputs.map(d => {
            return {
                id: d.id,
                name: d.name,
                document: stringifyDesignDocument(d.document, true),
                elements: d.elements.map(element => {
                    const ret = {
                        ...element,
                        properties: element.properties.map(prop => {
                            removeGraphQlTypename(prop);
                            if (prop.versionPropertyId) {
                                prop.value = undefined;
                            }
                            return prop;
                        })
                    };
                    removeGraphQlTypename(ret);
                    return ret;
                })
            } as ISerializedDesign;
        });

        const updatedElements = updatedDesigns.flatMap(design => design.elements);
        let updatedVersions = this.removeDanglingVersionProperties(updatedElements, dirtyVersions);
        updatedVersions = fixWidgetVersionProperties(updatedVersions, this.creativeset.creatives);

        return {
            creatives: dirtyCreatives.map(creative => ({
                id: creative.id,
                targetUrl: creative.targetUrl,
                checksum: creative.checksum
            })),
            dirtyCreatives,
            designs: updatedDesigns,
            versions: updatedVersions.map(v => ({
                id: v.id,
                properties: serializeVersionProperties(v.properties, this.sentinelService)
            })) as ISerializedVersion[]
        };
    }

    private deleteDesignsFromClient(deletedDesigns: IDesign[]): string[] {
        const { creatives, versions, elements } = this.creativeset;
        const deletedDesignIds = deletedDesigns.map(d => d.id);
        const updatedElements: IElement[] = [];
        const versionPropertyIds: string[] = [];

        for (const creative of creatives) {
            if (creative.design && deletedDesignIds.includes(creative.design.id)) {
                creative.design = undefined;
                /**
                 * Updated by SAPI but not crucial for it to have a value since design was removed
                 */
                creative.checksum = undefined;
            }
        }

        this.creativeset.designs = this.creativeset.designs.filter(
            d => !deletedDesignIds.includes(d.id)
        );

        for (const element of elements) {
            for (const design of this.creativeset.designs) {
                const designHasElement = design.elements.some(el => el.id === element.id);

                if (designHasElement) {
                    const elementExists = updatedElements.some(el => el.id === element.id);

                    if (!elementExists) {
                        updatedElements.push(element);
                    }

                    for (const property of element.properties) {
                        if (property.versionPropertyId) {
                            versionPropertyIds.push(property.versionPropertyId);
                        }
                    }
                }
            }
        }

        this.creativeset.elements = updatedElements;

        const propertiesIdsToRemove = new Set<string>();
        for (const version of versions) {
            for (const property of version.properties) {
                if (!versionPropertyIds.includes(property.id)) {
                    propertiesIdsToRemove.add(property.id);
                }
            }
        }

        return Array.from(propertiesIdsToRemove);
    }

    private updateCreativeChecksumsAndWeights(updatedCreatives: ICreative[]): void {
        for (const updatedCreative of updatedCreatives) {
            const oldCreative = this.creativeset.creatives.find(({ id }) => id === updatedCreative.id);

            if (oldCreative && oldCreative.checksum !== updatedCreative.checksum) {
                oldCreative.checksum = updatedCreative.checksum;
            }
        }

        this.creativesetChanged();
    }

    onVersionCreated(createdVersions: IVersion[], newCreatives: ICreative[]): void {
        this.creativeset.versions = mergeVersions(this.creativeset.versions, createdVersions);
        this.creativeset.creatives.push(...cloneDeep(newCreatives));
        this.creativesetChanged();
    }

    onVersionsUpdated(updatedVersions: IVersion[]): void {
        const nonUpdatedVersions = this.creativeset.versions.filter(
            version => !updatedVersions.find(updatedVersion => updatedVersion.id === version.id)
        );
        this.creativeset.versions = [...nonUpdatedVersions, ...updatedVersions];

        const updatedCreatives = this.creativeset.creatives.filter(creative =>
            updatedVersions.find(updatedVersion => creative.version.id === updatedVersion.id)
        );

        this.updateCreativeChecksumsAndWeights(updatedCreatives);
    }

    onVersionsDeleted(versionIds: string[]): void {
        this.creativeset.versions = this.creativeset.versions.filter(v => !versionIds.includes(v.id));
        this.creativeset.creatives = this.creativeset.creatives.filter(
            c => !versionIds.includes(c.version.id)
        );
        this.creativesetChanged();
    }

    onDefaultVersionUpdated(versionId: string): void {
        if (versionId === this.creativeset.defaultVersion.id) {
            return;
        }

        this.creativeset.defaultVersion = this.creativeset.versions.find(v => v.id === versionId)!;
        this.creativesetChanged();
    }

    private removeDanglingVersionProperties(elements: IElement[], versions: IVersion[]): IVersion[] {
        const elementProperties = elements.flatMap(element => element.properties);

        return versions.map(version => {
            const properties = version.properties.filter(({ id }) =>
                elementProperties.some(({ versionPropertyId }) => versionPropertyId === id)
            );

            return { ...version, properties: properties };
        });
    }
}
