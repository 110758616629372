import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    signal,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { IUIPopoverConfig } from '@bannerflow/ui';
import { isVersionedText } from '@creative/elements/rich-text/utils';
import { IElement } from '@domain/creativeset';
import { IVersion, IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { IFeed, IFeedStep } from '@domain/feed';
import { SpanType } from '@domain/text';
import {
    DirtyVersionPropertiesChanges,
    FeedPill,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { UserSettingsService } from '@studio/common/user-settings';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual } from '@studio/utils/utils';
import { take } from 'rxjs';
import { FeedSettingService } from '../../../../../../shared/components/feeds/feed-settings.service';
import { CreativesService } from '../../../../../../shared/creatives/state/creatives.service';
import { FeedStoreSingleton } from '../../../../singleton/feed-store-singleton';
import { TranslationPageService } from '../../../../state/translation-page.service';
import { changeFeedInVersionedText } from '../../../../utils/span.utils';

@Component({
    imports: [MatChipsModule, CommonModule],
    selector: 'feed-pills',
    templateUrl: './feed-pills.component.html',
    styleUrls: ['./feed-pills.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedPillsComponent implements OnChanges {
    @Input() expanded = false;
    @Input() elements: IElement[] = [];
    @Input() element: IElement;
    @Input() version: IVersion;
    @Input() versionProperty: IVersionProperty<IVersionedText>;
    @Input() visibleFeedSteps: (IFeedStep | undefined)[] = [];

    @Output() feedMouseEnter = new EventEmitter<FeedPill>();
    @Output() feedMouseLeave = new EventEmitter<FeedPill>();

    @ViewChild('chipSet', { read: ElementRef }) chipSet: ElementRef;

    pills: FeedPill[] = [];
    highlightedFeed = signal<IFeed | undefined>(undefined);

    private feedStore = FeedStoreSingleton.getInstance();
    private isLayoutDirectionRight: boolean;
    private dirtyProperties: VersionToDirtyProperties;

    constructor(
        private translationPageService: TranslationPageService,
        private creativesService: CreativesService,
        private userSettingsService: UserSettingsService,
        private feedSettingsService: FeedSettingService
    ) {
        this.translationPageService.dirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(dirtyProperties => {
                this.dirtyProperties = dirtyProperties;
            });
        this.userSettingsService.layout$.pipe(takeUntilDestroyed()).subscribe(layout => {
            this.isLayoutDirectionRight = layout === 'right';
        });

        this.feedSettingsService.feedValueChanged$
            .pipe(takeUntilDestroyed())
            .subscribe(({ newFeed, oldFeed }) => {
                const lastPillClicked = this.pills.find(
                    ({ id }) => id === this.feedSettingsService.lastFeedPillClicked
                );
                if (!lastPillClicked) {
                    return;
                }

                this.updateVersionProperties(newFeed, lastPillClicked);
                if (newFeed.path !== oldFeed.path) {
                    // When path changes, close the popover
                    this.feedSettingsService.close();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('element' in changes) {
            this.setFeeds();
            for (const { feed } of this.pills) {
                const elements = !this.expanded ? this.elements : [this.element];
                for (const element of elements) {
                    this.feedStore.addFeedElement(element.id, feed);
                }
            }
        }
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
        const target = event.target as HTMLElement;
        const chipSetElement = this.chipSet.nativeElement;
        if (!chipSetElement.contains(target) && this.highlightedFeed()) {
            this.onPillClickedOutside();
        }
    }

    async onPillClicked(
        { feed, id: pillId }: FeedPill,
        event: MouseEvent,
        index: number
    ): Promise<void> {
        this.feedSettingsService.lastFeedPillClicked = pillId;
        this.toggleHighlight(feed);

        if (this.element) {
            this.creativesService.focusElement(this.element.id, this.version.id);
        }

        const elementRef = new ElementRef(event.currentTarget);
        const config: IUIPopoverConfig = {
            position: this.isLayoutDirectionRight ? 'left' : 'right',
            arrowPosition: this.isLayoutDirectionRight ? 'right' : 'left',
            offset: { x: -5, y: 0 }
        };
        // Clone data so it doesn't get mutated
        const feedClone = cloneDeep(feed);

        const elementId = this.element?.id ?? this.elements[0].id;

        const visibleFeedStep = this.visibleFeedSteps.filter(Boolean)[index];

        await this.feedSettingsService.open(
            elementRef,
            elementId,
            feedClone,
            visibleFeedStep,
            this.feedStore,
            {
                disableEditSource: true
            },
            config
        );

        this.feedSettingsService.close$.pipe(take(1)).subscribe(() => {
            this.toggleHighlight(undefined);
            this.feedSettingsService.lastFeedPillClicked = undefined;
        });
    }

    onPillClickedOutside(): void {
        this.creativesService.blurElement();
    }

    onPillHovered(feedPill: FeedPill): void {
        this.feedMouseEnter.emit(feedPill);
        if (this.element) {
            this.creativesService.focusElement(this.element.id, this.version.id);
        }
    }

    onPillUnhovered(feedPill: FeedPill): void {
        const isPopoverOpen = this.feedSettingsService.openingInProcess || this.highlightedFeed();
        if (isPopoverOpen) {
            return;
        }
        this.feedMouseLeave.emit(feedPill);
        this.creativesService.blurElement();
    }

    private getVersionPropertyForElement(
        element: IElement,
        dirty = true
    ): IVersionProperty<IVersionedText> | undefined {
        const elementProperty = element.properties.find(({ versionPropertyId }) => versionPropertyId);
        if (!elementProperty) {
            return;
        }
        const versionProperty = this.version?.properties.find(
            ({ id }) => id === elementProperty.versionPropertyId
        );
        if (!isVersionedText(versionProperty)) {
            return;
        }

        const dirtyVersionProperty = this.dirtyProperties[this.version.id]?.[versionProperty.id];
        if (dirty && dirtyVersionProperty && isVersionedText(dirtyVersionProperty)) {
            return dirtyVersionProperty;
        }
        return versionProperty;
    }

    private updateVersionProperties(newFeed: IFeed, feedPill: FeedPill): void {
        const elements = !this.expanded ? this.elements : [this.element];

        const changes: DirtyVersionPropertiesChanges = [];

        for (const element of elements) {
            if (!element) {
                continue;
            }
            const oldVersionProperty = this.getVersionPropertyForElement(element);
            if (!oldVersionProperty) {
                continue;
            }

            const clonedValue = cloneDeep(oldVersionProperty.value);
            const newVersionedText = changeFeedInVersionedText(newFeed, feedPill, clonedValue);
            const newVersionPropertyValue: IVersionProperty<IVersionedText> = {
                ...oldVersionProperty,
                value: newVersionedText
            };

            const oldVersionPropertyValue = this.getVersionPropertyForElement(element, false)?.value;
            const action = deepEqual(oldVersionPropertyValue, newVersionedText) ? 'delete' : 'upsert';

            changes.push({
                versionId: this.version.id,
                versionProperty: newVersionPropertyValue,
                action
            });
        }
        if (changes.length) {
            this.translationPageService.modifyDirtyVersionProperties(changes);
        }
    }

    private toggleHighlight(feed: IFeed | undefined): void {
        this.highlightedFeed.set(feed);
    }

    private setFeeds(): void {
        const newPills: FeedPill[] = [];
        for (const span of this.versionProperty.value.styles) {
            if (span.type !== SpanType.Variable || !span.variable) {
                continue;
            }

            const id = `${this.element.id}*${span.variable.id}*${span.variable.path}*${span.position}`;
            newPills.push({
                id,
                feed: span.variable,
                span
            });
        }
        this.pills = newPills;
    }
}
