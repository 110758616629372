import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { IBrandLocalization } from '@domain/brand';
import { BrandService } from '@studio/common/brand';
import { map, Observable, take } from 'rxjs';
import { TruncateSpanComponent } from '../../../directives/truncate-span.directive';
import { VersionFromIdPipe } from '../../../pipes/version-from-id.pipe';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
    imports: [UIModule, CommonModule, VersionFromIdPipe, TruncateSpanComponent],
    selector: 'version-flag',
    templateUrl: './version-flag.component.html',
    styleUrls: ['./version-flag.component.scss']
})
export class VersionFlagComponent implements OnChanges {
    @Input() localizationId?: string;
    @Input() showName = false;

    @Input() versionId?: string;
    @Input() showVersionName = false;

    @Input() title?: string;

    @Input() isFullScreen?: boolean = false;
    @Input() sizeSelectLabel?: 'small' | 'default' = 'small';

    cultureCode?: string;
    name?: string;

    flagSize$: Observable<'tiny' | undefined>;

    private localizations: IBrandLocalization[] = [];

    constructor(
        private brandService: BrandService,
        private environmentService: EnvironmentService
    ) {
        this.brandService.localizations$.pipe(take(1)).subscribe(localizations => {
            this.localizations = localizations;
            this.onUpdate();
        });

        this.flagSize$ = this.environmentService.isMobile$.pipe(
            map(isMobile => (isMobile ? undefined : 'tiny'))
        );
    }

    ngOnChanges(): void {
        this.onUpdate();
    }

    private onUpdate(): void {
        if (this.localizationId) {
            const localization = this.localizations.find(l => l.id === this.localizationId);
            this.cultureCode = localization?.cultureCode;
            this.name = this.title ?? localization?.name;
        }
    }
}
