import { zodOptional } from '@zod/zod';
import { z } from 'zod';
import { ICreative } from './creative';

export const ZCorrelationId = z.object({
    correlationId: z.string()
});
export interface ICorrelationId extends z.infer<typeof ZCorrelationId> {}
export type ExportCreativeDto = z.infer<typeof ZCorrelationId>;
export const ZExportCreativeStatus = ZCorrelationId.extend({
    failures: z.array(z.string()),
    uploadedFilePath: zodOptional(z.string())
});
export type ExportCreativeStatusDto = z.infer<typeof ZExportCreativeStatus>;

export interface IExportCreativeRequest {
    creativesetId: string;
    type: ExportType;
    quality: number;
    loops?: number;
    creatives: IExportCreativeItem[];
}

export type ExportType = 'jpg' | 'gif' | 'png' | 'mp4';

export interface IExportCreativeItem {
    creativeId: string;
    frames: IExportCreativeFrame[];
}

export interface IExportCreativeFrame {
    time: number;
    duration: number;
}

export type GifFrameLimitMode = 'manual' | 'file-size' | 'frame-count';

export interface IExportSettings extends IExportGifSettings {
    type: ExportType;

    /**
     * BrandId only needed for video
     */
    brandId: string;

    /**
     * A quality between 0-100 for JPG and PNG. 1-256 for GIF.
     */
    quality: number;

    /**
     * Only for videos
     */
    frameRate?: number;

    creatives: ICreative[];

    email?: string; // Used for showcase export
}

export interface IExportGifSettings {
    /**
     * Loops only for GIF. Provide 0 or undefined for infinite looping.
     */
    loops?: number;
    fileSizeLimit?: number;
    frameLimit?: number;
    gifFrameLimitMode?: GifFrameLimitMode;
}

export interface IExportVideoCreative {
    width: number;
    height: number;
    duration: number;
    id: number;
    fps: number;
}

export interface IExportVideoRequest {
    creativeSetId: number;
    creatives: IExportVideoCreative[];
    email?: string; // Used for showcase export
}

export interface IExportCreativeResult {
    createExportCreativeRequest: ExportCreativeDto;
}

export interface IExportCreativeVariables {
    brandId: string;
    exportCreativeRequest: IExportCreativeRequest;
}

export interface IExportCreativeStatusResult {
    exportCreativeRequestByCorrelationId: ExportCreativeStatusDto;
}

export interface IExportCreativeStatusVariables extends ICorrelationId {
    creativesetId: string;
}

export interface IGifFrame {
    time: number;
    duration?: number;
    importance?: number;
    composition?: number[];
}
