import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative/creative';
import { ICampaignStatus } from '@studio/domain/campaigns';
import { HeavyAssetMetadata } from '../../../../../../../libs/studio/domain/src/api/heavy-assets.types';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'publish-changes-dialog',
    templateUrl: './publish-changes-dialog.component.html',
    styleUrls: ['./publish-changes-dialog.component.scss']
})
export class PublishChangesDialogComponent {
    private navigationService = inject(NavigationService);
    private dialog = inject(UIDialogComponent);
    private heavyAssetMetadataSignal = signal<HeavyAssetMetadata[] | undefined>(undefined);

    hasHeavyAssets = computed(() => this.computeHasHeavyAssets());
    heavyFileNames = computed(() => this.computeHeavyFileNames());

    campaigns: ICampaignStatus[] = [];
    creatives: ICreative[];

    constructor() {
        this.heavyAssetMetadataSignal.set(this.dialog.config.data.heavyAssetMetadata);
        this.campaigns = this.dialog.config.data.campaigns as ICampaignStatus[];
        this.creatives = this.dialog.config.data.creatives as ICreative[];
    }

    cancel(): void {
        this.dialog.dialogRef.close();
    }

    openCampaign(id: string): void {
        this.navigationService.openCampaign(id);
    }

    publishChanges(): void {
        this.dialog.config.data.confirmed = true;
        this.dialog.dialogRef.close();
    }

    private computeHasHeavyAssets(): boolean {
        return !!this.heavyAssetMetadataSignal()?.some(m => m.heavyAssets?.length > 0);
    }

    private computeHeavyFileNames(): string {
        return (
            this.heavyAssetMetadataSignal()
                ?.flatMap(m => m.heavyAssets)
                .map(asset => asset.fileName)
                .join(', ') ?? ''
        );
    }
}
