<ng-container *ngIf="creatives">
    <div
        class="summary top"
        *ngIf="creatives.length">
        {{ creatives.length }} creative{{ creatives.length > 1 ? 's' : '' }} selected
    </div>

    <!-- GIF Specific settings -->
    <ng-container *ngIf="type === 'gif'">
        <div class="grid">
            <ui-checkbox
                label="Optimize colors"
                [(selected)]="optimizeGifColors"></ui-checkbox>
            <div>
                <studio-ui-slider
                    id="interaction-export-color-slider"
                    [disabled]="!optimizeGifColors"
                    [config]="{ min: 1, max: 100 }"
                    [(value)]="quality">
                </studio-ui-slider>
            </div>
            <div>
                <ui-number-input
                    id="interaction-export-color-input"
                    unitLabel="colors"
                    [arrowButtons]="false"
                    [disabled]="!optimizeGifColors"
                    [allowEmpty]="false"
                    [min]="2"
                    [max]="256"
                    [(value)]="gifColors">
                </ui-number-input>
            </div>
        </div>
        <ui-selectable-list
            #list
            [(selected)]="limitMode"
            [multiSelect]="false"
            class="grid gif tight">
            <!-- Manual -->
            <div class="manual-option tripple">
                <ui-radio
                    id="interaction-use-manual-gif-frames"
                    [disabled]="!manualGifFramesEnabled"
                    [label]="manualGifFrameLabel"
                    [value]="'manual'">
                </ui-radio>
            </div>

            <!-- Frame limit -->
            <div class="double">
                <ui-radio
                    id="interaction-export-frames-radio"
                    label="Limit number of frames"
                    [value]="'frame-count'">
                </ui-radio>
            </div>
            <div [ngClass]="{ inactive: limitMode !== 'frame-count' }">
                <ui-number-input
                    id="interaction-export-frames-input"
                    unitLabel="frames"
                    [(value)]="frameLimit"
                    [allowEmpty]="false"
                    [min]="1"
                    [max]="100">
                </ui-number-input>
            </div>

            <!-- File size limit -->
            <div class="double">
                <ui-radio
                    id="interaction-export-size-radio"
                    label="Limit file size"
                    [value]="'file-size'">
                </ui-radio>
            </div>
            <div [ngClass]="{ inactive: limitMode !== 'file-size' }">
                <ui-number-input
                    id="interaction-export-size-input"
                    unitLabel="KB"
                    [(value)]="fileSizeLimit"
                    [allowEmpty]="false"
                    [step]="10"
                    [min]="0"
                    [max]="10000">
                </ui-number-input>
            </div>
        </ui-selectable-list>
        <div class="loop-settings">
            <div class="grid">
                <div class="tripple align-right">
                    <div>Set Loop Count</div>
                    <ui-select
                        id="interaction-loops-setting"
                        [(selected)]="loopsSettings"
                        width="143px">
                        <ui-option
                            [value]="'Forever'"
                            (click)="loops = undefined"
                            >Forever</ui-option
                        >
                        <ui-option
                            [value]="'Custom'"
                            (click)="loops = 1"
                            >Custom</ui-option
                        >
                    </ui-select>
                </div>
            </div>
            <div
                class="grid"
                *ngIf="loopsSettings === 'Custom'">
                <div class="tripple align-right">
                    <div>Play</div>
                    <ui-number-input
                        [ngClass]="{ error: (loops || 0) < 1 }"
                        id="interaction-custom-loops"
                        unitLabel="times"
                        [(value)]="loops">
                    </ui-number-input>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- JPEG Specific settings -->
    <div
        class="grid jpg"
        *ngIf="type === 'jpg'">
        <div>Quality</div>
        <div>
            <studio-ui-slider
                [(value)]="quality"
                [config]="{ min: 1, max: 100 }">
            </studio-ui-slider>
        </div>
        <div>
            <ui-number-input
                unitLabel="%"
                [arrowButtons]="false"
                [(value)]="quality"
                [allowEmpty]="false"
                [min]="1"
                [max]="100">
            </ui-number-input>
        </div>
    </div>

    <!-- Video Specific settings -->
    <div
        class="mp4"
        *ngIf="type === 'mp4'">
        <ui-selectable-list
            class="radio-list"
            videoFPS
            [(selected)]="videoFrameRate"
            [multiSelect]="false">
            <ui-radio
                class="radio-list-button"
                label="30 frames per second"
                [value]="30">
            </ui-radio>
            <ui-radio
                class="radio-list-button"
                label="60 frames per second"
                [value]="60">
            </ui-radio>
        </ui-selectable-list>
    </div>

    @if (inShowcaseMode()) {
        <div class="email-input-container">
            <ui-input
                placeholder="Ex. email@domain.com"
                label="Please enter your email address"
                [value]="emailValue()"
                [validation]="emailControl"
                (valueChange)="emailValue.set($event)" />
        </div>
    }

    <div
        class="summary bottom"
        *ngIf="type !== 'mp4'">
        The number of creatives
        <ng-container *ngIf="type === 'gif'">and their duration</ng-container> directly affect the
        processing time.
    </div>

    <div
        class="summary bottom"
        *ngIf="type === 'mp4'">
        The number of creatives and their duration directly affect the processing time. You will receive
        an email with a download link when your files are ready.
    </div>

    <ui-dialog-buttons>
        <ui-button
            text="Cancel"
            (click)="cancel()">
        </ui-button>
        <ui-button
            [id]="'export-' + type"
            type="primary"
            [disabled]="
                !exportButtonEnabled() ||
                (type === 'gif' && loopsSettings === 'Custom' && (loops || 0) < 1)
            "
            [text]="buttonLabel"
            (click)="export()">
        </ui-button>
    </ui-dialog-buttons>
</ng-container>
