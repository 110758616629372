import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BEGIN_CREATIVE_GENERATION } from '@data/graphql/creative.queries';
import { ICreative, ICreativeDto } from '@domain/creativeset/creative';
import { ICampaignStatus, ListDisplayCampaignsResponse } from '@studio/domain/campaigns';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DisplayCampaignDataService {
    private httpClient = inject(HttpClient);
    private apolloClient = inject(Apollo);

    loadCampaignStatuses(
        campaignIds: string[],
        accountSlug: string,
        brandSlug: string
    ): Observable<ICampaignStatus[]> {
        const url = this.getURLForCampaigns(campaignIds, accountSlug, brandSlug);
        return this.httpClient.get<ICampaignStatus[]>(url);
    }

    pushChanges(
        creativesetId: string,
        creatives: ICreative[]
    ): Observable<{
        creatives: Pick<ICreativeDto, 'id' | 'checksum'>[];
        failures: Pick<ICreativeDto, 'id' | 'checksum'>[];
    }> {
        const creativeIds = creatives.map(creative => creative.id);

        return this.apolloClient
            .mutate({
                mutation: BEGIN_CREATIVE_GENERATION,
                variables: {
                    creativeIds,
                    creativesetId
                }
            })
            .pipe(
                map(({ data }) => {
                    if (!data?.beginCreativeGeneration) {
                        throw new Error('No beginCreativeGeneration data');
                    }

                    return data.beginCreativeGeneration;
                })
            );
    }

    getCampaignsConnectedTo(creativeSetId: string): Observable<ListDisplayCampaignsResponse> {
        const url = `${environment.origins.sapi}/campaigns/connected-to/${creativeSetId}`;
        return this.httpClient.get<ListDisplayCampaignsResponse>(url);
    }

    private getBaseURL(accountSlug: string, brandSlug: string): string {
        return `${environment.origins.campaignService}/api/${accountSlug}/${brandSlug}/campaign`;
    }

    private getURLForCampaigns(campaignIds: string[], accountSlug: string, brandSlug: string): string {
        const baseUrl = `${this.getBaseURL(accountSlug, brandSlug)}`;
        return `${baseUrl}/status?ids=${campaignIds.join('&ids=')}`;
    }
}
