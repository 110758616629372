import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationError, Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { Logger } from '@bannerflow/sentinel-logger';
import { isKnownErrorResponse } from '@studio/domain/api/error';
import { BrandService } from '@studio/common/brand';

@Injectable({
    providedIn: 'root'
})
export class ErrorsRedirectionService {
    private brandService = inject(BrandService);
    private logger = new Logger('ErrorsRedirectionService');
    public error: HttpErrorResponse | ApolloError | undefined;

    constructor(
        private router: Router,
        private location: Location
    ) {
        this.router.events.subscribe(event => {
            this.logger.debug(event);

            if (event instanceof NavigationError) {
                this.handleErrorRedirection(event.error).then(() => {
                    // Show the route that couldn't be navigated to in the browser
                    this.location.replaceState(event.url);
                });
            }
        });

        this.brandService.error$.pipe(takeUntilDestroyed()).subscribe(error => {
            if (!error) {
                return;
            }
            this.handleErrorRedirection(error);
        });
    }

    handleErrorRedirection(error: Error | HttpErrorResponse | ApolloError | unknown): Promise<boolean> {
        if (isHttpError(error)) {
            this.error = error;
            return this.handleHttpErrorResponse(error);
        }

        if (error instanceof ApolloError) {
            this.error = error;
            return this.routeTo('404'); // Error will be handled in 404Component
        }

        return this.handleNotFoundError();
    }

    handleHttpErrorResponse(error: HttpErrorResponse): Promise<boolean> {
        const isHandledErrorResponse = isKnownErrorResponse(error);

        if (isHandledErrorResponse) {
            return this.routeTo('404');
        }

        return this.routeTo('oops');
    }

    private handleNotFoundError(): Promise<boolean> {
        return this.routeTo('404');
    }

    private routeTo(url: string): Promise<boolean> {
        return this.router.navigate([url], { skipLocationChange: true });
    }
}

function isHttpError(error: unknown): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
}
