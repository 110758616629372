import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild
} from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIInputComponent, UIModule, UINotificationService } from '@bannerflow/ui';
import { IBrandLocalization } from '@domain/brand';
import { IVersion } from '@domain/creativeset/version';
import { BrandService } from '@studio/common/brand';
import { isElementDescendantOfElement } from '@studio/utils/dom-utils';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, takeUntil } from 'rxjs';
import { TranslationPanelService } from '../../../pages/manage-view/translation-panel/translation-panel.service';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { FiltersService } from '../../filters/state/filters.service';
import { EnvironmentService } from '../../services/environment.service';
import { VersionsService } from '../../versions/state/versions.service';
import { ToolbarButtonComponent } from '../toolbar-button/toolbar-button.component';
import { VersionDialogService } from '../version-dialog/version-dialog.service';
import { VersionLanguagePickerComponent } from '../version-language-picker/version-language-picker.component';
import { VersionFlagComponent } from './version-flag/version-flag.component';

type VersionPickerViewMode = 'show' | 'select' | 'select-localization' | 'add' | 'loading';

@Component({
    imports: [
        UIModule,
        CommonModule,
        PermissionsDirective,
        VersionFlagComponent,
        VersionLanguagePickerComponent,
        ToolbarButtonComponent
    ],
    selector: 'version-picker',
    templateUrl: 'version-picker.component.html',
    styleUrls: ['version-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionPickerComponent implements OnInit, OnDestroy {
    @Input() isMobile = false;
    @Input() showAllVersions: boolean;
    @Input() allowManageVersions: boolean;
    @Output() pickerOpen = new EventEmitter<void>();
    @Output() pickerClose = new EventEmitter<void>();

    @ViewChild('versionNameInput') versionNameInput: UIInputComponent;

    private viewMode: VersionPickerViewMode = 'show';

    private _viewMode$ = new BehaviorSubject<VersionPickerViewMode>('show');
    viewMode$ = this._viewMode$.asObservable();

    localizations$: Observable<IBrandLocalization[]>;
    isShowingAllVersions$: Observable<boolean>;
    selectedVersions$: Observable<IVersion[]>;
    versions$: Observable<IVersion[]>;
    newVersionPlaceholder$: Observable<IVersion | undefined>;

    newVersionName = '';

    private unsubscribe$ = new Subject<void>();
    private logger = new Logger('VersionPickerComponent');
    private isMultiSelecting = false;
    private selectedVersions: IVersion[] = [];
    flagSize$: Observable<'tiny' | undefined>;
    isMobileShowcase$: Observable<boolean>;
    versionPickerArrow$: Observable<'arrow-up' | 'arrow-down'>;
    autoTranslate: boolean;

    constructor(
        private host: ElementRef,
        private uiNotificationService: UINotificationService,
        private brandService: BrandService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService,
        private filtersService: FiltersService,
        // version dialog service is available only when manageview module is loaded
        @Optional() @SkipSelf() private versionDialogService?: VersionDialogService,
        @Optional() @SkipSelf() private translationPanelService?: TranslationPanelService
    ) {
        this.isMobileShowcase$ = this.environmentService.isMobileShowcase$;
        this.versionPickerArrow$ = combineLatest([
            this.viewMode$,
            this.environmentService.isMobile$
        ]).pipe(
            map(([viewMode, isMobile]) => {
                const isOpen = viewMode === 'select-localization' || viewMode === 'select';
                if (isMobile) {
                    return isOpen ? 'arrow-down' : 'arrow-up';
                }
                return isOpen ? 'arrow-up' : 'arrow-down';
            })
        );
        this.flagSize$ = this.environmentService.isMobileShowcase$.pipe(
            map(isMobileShowcase => (isMobileShowcase ? undefined : 'tiny'))
        );
    }

    ngOnInit(): void {
        this.newVersionPlaceholder$ = this.versionsService.newVersionPlaceholder$;
        this.selectedVersions$ = this.versionsService.selectedVersions$;
        this.isShowingAllVersions$ = this.filtersService.isShowingAllVersions$;
        this.localizations$ = this.brandService.localizations$;
        this.versions$ = this.versionsService.selectableVersions$;
        this.versionsService.selectedVersions$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersions => {
                this.selectedVersions = selectedVersions;
                if (!this.isMultiSelecting) {
                    this.setViewMode('show');
                }
                this.isMultiSelecting = false;
            });

        this.viewMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(viewMode => {
            this.viewMode = viewMode;
            if (viewMode === 'select' || viewMode === 'select-localization') {
                this.pickerOpen.emit();
            } else {
                this.pickerClose.emit();
            }
        });

        this.versionsService.newVersionPlaceholder$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.viewMode !== 'add')
            )
            .subscribe(newVersionPlaceholder => {
                if (!newVersionPlaceholder) {
                    this.newVersionName = '';
                    this.setViewMode('show');
                    return;
                }

                this.newVersionName = newVersionPlaceholder.name;
                this.setViewMode('add');
                setTimeout(() => {
                    this.versionNameInput?.focus?.(true);
                });
            });

        this.versionsService.loaded$.pipe(takeUntil(this.unsubscribe$)).subscribe(loaded => {
            this.setViewMode(loaded ? 'show' : 'loading');
        });

        this.versionsService.error$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(error => !!error)
            )
            .subscribe(error => {
                this.uiNotificationService.open((error as Error).message, {
                    autoCloseDelay: 5000,
                    type: 'error',
                    placement: 'top'
                });
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toggleSelectView(): void {
        const newViewMode = this.viewMode === 'select' ? 'show' : 'select';
        this.setViewMode(newViewMode);
    }

    showAddLocalizationView(event?: Event): void {
        event?.stopPropagation();
        this.setViewMode('select-localization');
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        if (!isElementDescendantOfElement(this.host.nativeElement, event.target)) {
            this.deselect();
        }
    }

    @HostListener('window:keydown.escape')
    onEscapeKeyDown(): void {
        this.deselect();
    }

    private deselect(): void {
        switch (this.viewMode) {
            case 'select-localization':
                this.setViewMode('select');
                break;
            case 'select':
            default:
                this.setViewMode('show');
                break;
        }
    }

    private async checkTranslationPanelPristiness(): Promise<boolean> {
        if (!this.translationPanelService) {
            return true;
        }
        return await this.translationPanelService.isPristine();
    }

    async selectVersion(event: MouseEvent, version: IVersion): Promise<void> {
        const canContinue = await this.checkTranslationPanelPristiness();
        if (!canContinue) {
            return;
        }
        const defmodKey = navigator.userAgent.includes('Mac OS X') ? event.metaKey : event.ctrlKey;
        const multiSelect = defmodKey && this.allowManageVersions;

        this.isMultiSelecting = multiSelect;
        if (this.isMultiSelecting && this.selectedVersions.find(v => v.id === version.id)) {
            if (this.selectedVersions.length > 1) {
                this.filtersService.deselectVersion(version);
            }
        } else {
            this.filtersService.selectVersion(version, multiSelect);
        }
    }

    async selectAllVersions(): Promise<void> {
        const canContinue = await this.checkTranslationPanelPristiness();
        if (!canContinue) {
            return;
        }
        this.filtersService.selectAllVersions();
    }

    selectLocalization(localization: IBrandLocalization, autoTranslate: boolean): void {
        this.autoTranslate = autoTranslate;
        this.versionsService.prepareNewVersion(localization);
    }

    stopAddVersion(): void {
        this.versionsService.cancelNewVersion();
    }

    saveNewVersion(): void {
        this.versionsService.saveNewVersion(this.newVersionName, this.autoTranslate);
    }

    openVersionDialog(): void {
        this.versionDialogService?.openVersionDialog(this.onVersionDialogClosed);
        this.setViewMode('show');
    }

    onVersionDialogClosed = (): void => {
        this.setViewMode('show');
    };

    private setViewMode(newViewMode: VersionPickerViewMode): void {
        this._viewMode$.next(newViewMode);
    }
}
