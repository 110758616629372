import { Component, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { MIN_ELEMENT_DURATION } from '@creative/animation.utils';
import { Color } from '@creative/color';
import { isVideoNode } from '@creative/nodes';
import { IAnimator } from '@domain/creative/animator.header';
import { IGifExport, IPreloadImage } from '@domain/creativeset/creative';
import { ICreativeDataNode } from '@domain/nodes';
import { ISocialPlacement } from '@domain/social';
import { GuidelineType, IGuideline } from '@domain/workspace';
import { SOCIAL_PLACEMENTS } from '@studio/domain/constants/social';
import { StudioFeatureFlags } from '@studio/domain/feature-flags/studio.ff.types';
import { ISocialGuide, ISocialNetwork } from '@studio/domain/social';
import { UserSettingsService } from '@studio/common/user-settings';
import { uuidv4 } from '@studio/utils/id';
import { Observable, combineLatest, merge } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ColorService } from '../../../../shared/services/color.service';
import { SocialGuideService } from '../../../../shared/services/social-guide.service';
import { DesignViewComponent } from '../../../index';
import { EditorEventService, EditorStateService } from '../../services';
import { ElementChangeType } from '../../services/editor-event';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { MAX_TIMELINE_DURATION } from '../../timeline/timeline.constants';

@Component({
    selector: 'creative-properties',
    templateUrl: './creative-properties.component.html',
    styleUrls: ['./creative-properties.component.scss', '../common.scss'],
    standalone: false
})
export class CreativePropertiesComponent implements OnInit {
    @Input() animator: IAnimator;
    @Input() editor: DesignViewComponent;

    fill = new Color();
    duration = 0;
    minTimelineDuration = MIN_ELEMENT_DURATION;
    maxTimelineDuration = MAX_TIMELINE_DURATION;
    loops = 0;
    startTime: number;
    stopTime: number | undefined;
    audio = false;
    preloadImage: IPreloadImage;
    gifExport: IGifExport;
    creativeDocument: ICreativeDataNode;
    ElementChangeType = ElementChangeType;
    isAudioSettingVisible = false;

    socialNetworks$: Observable<ISocialNetwork[]>;
    selectedNetwork$: Observable<ISocialNetwork>;
    selectedPlacement$: Observable<ISocialPlacement>;
    showOverlay$: Observable<boolean>;
    showGuidelines$: Observable<boolean>;
    isSocialCreative$: Observable<boolean>;
    guidelineVisible$: Observable<boolean>;

    private historyService = inject(HistoryService);
    private socialGuideService = inject(SocialGuideService);
    private colorService = inject(ColorService);
    private userSettingsService = inject(UserSettingsService);
    private mutatorService = inject(MutatorService);
    private editorEvent = inject(EditorEventService);
    private editorStateService = inject(EditorStateService);
    private ffFeatureFlagService = inject(FFFeatureFlagsService);

    private optInAudioFFEnabled = toSignal(
        this.ffFeatureFlagService.isEnabled$(StudioFeatureFlags.OptInAudio),
        { initialValue: false }
    );

    constructor() {
        this.creativeDocument = this.editorStateService.document;
        this.isSocialCreative$ = this.socialGuideService.isSocialCreative$;
        this.socialNetworks$ = this.socialGuideService.socialNetworks$;
        this.selectedNetwork$ = this.socialGuideService.selectedNetwork$;
        this.selectedPlacement$ = this.socialGuideService.selectedPlacement$;
        this.showOverlay$ = this.socialGuideService.showOverlay$;
        this.showGuidelines$ = this.socialGuideService.showGuidelines$;
        this.guidelineVisible$ = this.userSettingsService.guidelineVisible$;

        merge(
            this.historyService.snapshotApply$,
            this.editorEvent.creative.change$,
            this.editorStateService.renderer$
        )
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.creativeDocument = this.mutatorService.creativeDocument;
                this.updateProperties();
            });

        this.isSocialCreative$
            .pipe(
                takeUntilDestroyed(),
                filter(isSocial => isSocial),
                switchMap(() =>
                    combineLatest({
                        network: this.selectedNetwork$,
                        placement: this.selectedPlacement$,
                        guidelines: this.showGuidelines$,
                        overlay: this.showOverlay$
                    })
                )
            )
            .subscribe((socialGuide: ISocialGuide) => {
                this.setSocialGuide(socialGuide);
                this.renderSocialGuidelines();
            });
    }

    ngOnInit(): void {
        this.updateProperties();
        this.socialGuideService.initSocialCreative(this.creativeDocument);
    }

    toggleColorPicker(): void {
        this.colorService.toggleColorPicker('creativeFill');
    }

    setFill(fill: Color, eventType?: ElementChangeType): void {
        this.fill = fill;
        this.updateFill(eventType);
    }

    setFillAlpha(alpha: number, eventType?: ElementChangeType): void {
        const newColor = new Color(this.fill);
        newColor.alpha = alpha;
        this.setFill(newColor, eventType);
    }

    updateFill(eventType?: ElementChangeType): void {
        this.mutatorService.setCreativeAppearance('fill', this.fill, eventType);
    }

    previewFill(color: Color): void {
        this.mutatorService.setCreativeAppearance('fill', color);
    }

    previewStop(color: Color): void {
        this.fill = color;
        this.updateFill();
    }

    setLoops(): void {
        this.mutatorService.setCreativeLoops(this.loops);

        if (this.loops === 0) {
            this.stopTime = undefined;
        } else {
            this.stopTime = this.creativeDocument.getStopTime_m();
        }

        this.setStopTime(false);
    }

    showManualGifFrames(show: boolean): void {
        this.mutatorService.showManualGifFrames(show);
    }

    setStopTime(emitChanges = true): void {
        this.stopTime = Math.max(0, this.stopTime ?? 0);
        this.mutatorService.setCreativeStopTime(this.stopTime, emitChanges);
    }

    setAudio(enabled: boolean): void {
        this.audio = enabled;
        this.mutatorService.setCreativeAudio(enabled);
    }

    setStartTime(emitChanges = true): void {
        this.mutatorService.setAnimationStartTime(this.startTime, emitChanges);
        this.startTime = Math.max(0, this.startTime);
    }

    setDuration(): void {
        this.mutatorService.setCreativeDuration(this.duration);
    }

    setPreloadImage(): void {
        this.mutatorService.setPreloadImage(this.preloadImage);
    }

    undo(): void {
        this.historyService.undo$.next();
    }

    redo(): void {
        this.historyService.redo$.next();
    }

    setNetwork(network: ISocialNetwork): void {
        this.socialGuideService.selectNetwork(network);
    }

    setPlacement(placement: ISocialPlacement): void {
        this.socialGuideService.selectPlacement(placement);
    }

    showSocialOverlay(show: boolean): void {
        this.socialGuideService.setShowOverlay(show);
    }

    showSocialGuidelines(show: boolean): void {
        this.socialGuideService.setShowGuidelines(show);
    }

    checkAudioSettingVisible(): boolean {
        const hasVideo = this.editorStateService.document.elements.some(isVideoNode);

        if (hasVideo) {
            return this.optInAudioFFEnabled();
        } else if (this.audio) {
            this.setAudio(false);
        }

        return false;
    }

    private updateProperties(): void {
        const { audio, fill, loops, preloadImage, gifExport, startTime } = this.creativeDocument;
        this.isAudioSettingVisible = this.checkAudioSettingVisible();

        this.fill = fill;
        this.loops = loops;
        this.audio = audio ?? false;
        this.stopTime = this.creativeDocument.getStopTime_m();
        this.startTime = startTime ?? 0;
        this.preloadImage = { ...preloadImage };
        this.gifExport = gifExport;

        if (!this.preloadImage.frames || this.preloadImage.frames.length === 0) {
            this.preloadImage.frames = [this.creativeDocument.getFirstPreloadImageFrame()];
        }

        if (this.animator) {
            this.duration = this.creativeDocument.duration;
        }
    }

    private setSocialGuide(socialGuide: ISocialGuide): void {
        this.mutatorService.setCreativeSocialGuide(socialGuide);
    }

    private renderSocialGuidelines(): void {
        const creativeDocument = this.creativeDocument;
        const socialGuide = creativeDocument.socialGuide;
        if (socialGuide?.guidelines) {
            const creativeHeight = creativeDocument.height;
            const creativeWidth = creativeDocument.width;
            const guidelinePosition = SOCIAL_PLACEMENTS.find(
                ({ type }) => type === socialGuide.placement
            )?.position;

            if (!guidelinePosition) {
                throw new Error('Could not get guideline position');
            }

            const guideLineTopYPos = Math.floor(creativeHeight * guidelinePosition.top);
            const guideLineBottomYPos = Math.floor(creativeHeight * guidelinePosition.bottom);

            const socialGuideLines: IGuideline[] = [
                {
                    id: uuidv4(),
                    position: { x: 0, y: guideLineTopYPos },
                    preview: false,
                    type: GuidelineType.Horizontal,
                    social: true
                },
                {
                    id: uuidv4(),
                    position: { x: 0, y: creativeHeight - guideLineBottomYPos },
                    preview: false,
                    type: GuidelineType.Horizontal,
                    social: true
                }
            ];

            if (guidelinePosition.left && guidelinePosition.right) {
                const guideLineLeftXPos = Math.floor(creativeWidth * guidelinePosition.left);
                const guideLineRightXPos = Math.floor(creativeWidth * guidelinePosition.right);

                socialGuideLines.push(
                    {
                        id: uuidv4(),
                        position: { x: guideLineLeftXPos, y: 0 },
                        preview: false,
                        type: GuidelineType.Vertical,
                        social: true
                    },
                    {
                        id: uuidv4(),
                        position: { x: creativeWidth - guideLineRightXPos, y: 0 },
                        preview: false,
                        type: GuidelineType.Vertical,
                        social: true
                    }
                );
            }

            creativeDocument.guidelines = creativeDocument.guidelines.filter(
                guideline => !guideline.social
            );

            this.mutatorService.creativeDocument.guidelines = [
                ...this.creativeDocument.guidelines,
                ...socialGuideLines
            ];
        }

        this.editor?.workspace.gizmoDrawer.draw();
    }
}
