<ng-container *ngIf="{ loading: !!(loading$ | async) } as templateData">
    <ui-button
        id="add-version-button"
        type="default"
        text="Add version"
        icon="plus-skinny-minimal"
        [loading]="templateData.loading"
        [uiDropdownTarget]="localizationsDropdown.dropdown">
    </ui-button>
    <version-language-picker
        #localizationsDropdown
        (selectLocalization)="addVersion($event.localization, $event.autoTranslate)"
        [offset]="{ x: 0, y: 30 }"
        [positions]="[
            {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top'
            }
        ]"></version-language-picker>
    <ng-container *ngIf="{ defaultVersion: defaultVersion$ | async } as versionsData">
        <ui-list
            #list
            class="list"
            [expandable]="false"
            [dataSource]="versionsDataSource"
            [selectionType]="'single'"
            columnHeight="8rem">
            <!-- Name column -->
            <ui-list-column
                name="Name"
                property="name"
                [sortable]="true">
                <ng-template
                    let-data="data"
                    let-rowIndex="rowIndex"
                    let-displayValue="displayValue"
                    let-value="value"
                    ui-list-cell-template>
                    <div class="inline-edit">
                        <span class="text">{{ data.name }}</span>
                        <span
                            class="dimmed"
                            *ngIf="data.id === versionsData.defaultVersion?.id"
                            >(default)</span
                        >
                    </div>
                    <ui-input
                        autocomplete="off"
                        [attr.data-test-id]="'localization-name-' + data.name"
                        [discrete]="true"
                        [(value)]="data.name"
                        [maxlength]="250"
                        (submit)="nameChanged(data)"
                        (blur)="nameChanged(data)"
                        [disabled]="templateData.loading"
                        [validation]="nameValidationMap.get(data)"></ui-input>
                    <span
                        *ngIf="
                            nameValidationMap.get(data)?.invalid &&
                            !!data.name &&
                            nameValidationMap.get(data)?.touched
                        "
                        class="warning-text"
                        >{{ nameValidationMap.get(data)?.errors?.name }}</span
                    >
                </ng-template>
            </ui-list-column>

            <!-- Language column -->
            <ui-list-column
                name="Language"
                property="localization.name"
                [sortable]="true">
                <ng-template
                    let-data="data"
                    ui-list-cell-template>
                    <ui-select
                        *ngIf="data && !data.isInCampaign"
                        theme="default"
                        width="150px"
                        [discrete]="true"
                        data-test-id="cell-language"
                        [disabled]="templateData.loading || data.isInCampaign"
                        (selectedChange)="onLocalizationChange(data, $event)"
                        [(selected)]="data.localization.id">
                        <ng-container ui-select-label>
                            <version-flag
                                [localizationId]="data.localization.id"
                                [showName]="true"></version-flag>
                        </ng-container>
                        <ui-option
                            *ngFor="let localization of localizations$ | async"
                            [value]="localization">
                            <version-flag
                                [localizationId]="localization.id"
                                [showName]="true"></version-flag>
                        </ui-option>
                    </ui-select>
                    <div
                        *ngIf="data?.isInCampaign"
                        class="localization-campaign"
                        data-test-id="cell-language-campaign">
                        <version-flag
                            uiTooltipTrigger="hover"
                            uiTooltip="This version is currently used in at least one campaign."
                            sizeSelectLabel="default"
                            [uiTooltipInteractive]="true"
                            [localizationId]="data.localization.id"
                            [showName]="true">
                        </version-flag>
                    </div>
                </ng-template>
            </ui-list-column>

            <!-- TargetUrl Column -->
            <ui-list-column
                name="Target URL"
                property="targetUrl"
                [sortable]="true"
                [border]="false">
                <ng-template
                    let-data="data"
                    let-displayValue="displayValue"
                    let-value="value"
                    ui-list-cell-template>
                    <div class="url-group">
                        <div class="input-group">
                            <ui-input
                                id="interaction-target-url"
                                #urlInput
                                class="url-input"
                                autocomplete="off"
                                [autofocus]="false"
                                [discrete]="true"
                                [validation]="targetUrlValidationMap.get(data)!"
                                placeholder="Target URL"
                                [(value)]="data.targetUrl"
                                [disabled]="templateData.loading"
                                (valueChange)="targetUrlValidationMap.get(data)?.markAsTouched()"
                                (submit)="targetUrlChanged(data)"
                                (blur)="targetUrlChanged(data)">
                            </ui-input>
                            <!-- (valueChange)="valueChange()" -->
                            <ui-svg-icon
                                class="warning"
                                *ngIf="!data.targetUrl"
                                fill="#363636"
                                icon="warning"
                                uiTooltip="Target URL missing"
                                uiTooltipPosition="bottom">
                            </ui-svg-icon>
                        </div>
                    </div>
                    <span
                        *ngIf="!data.targetUrl"
                        class="warning-text"
                        >Version target URL cannot be empty</span
                    >
                    <span
                        *ngIf="
                            targetUrlValidationMap.get(data)?.invalid &&
                            !!data.targetUrl &&
                            targetUrlValidationMap.get(data)?.touched
                        "
                        class="warning-text"
                        >Invalid URL</span
                    >
                </ng-template>
            </ui-list-column>

            <!-- Menu column -->
            <ui-list-column
                property="name"
                width="50px"
                align="center">
                <ng-template
                    let-data="data"
                    ui-list-cell-template>
                    <ui-svg-icon
                        [ngClass]="{
                            'settings-icon': true,
                            open: isKebabOpen
                        }"
                        icon="kebab"
                        [uiDropdownTarget]="settingsDropdown"
                        (click)="onKebabMenuOpen(true)"></ui-svg-icon>
                    <ui-dropdown
                        theme="default"
                        (close)="onKebabMenuOpen(false)"
                        #settingsDropdown
                        [disabled]="templateData.loading">
                        <ui-dropdown-item
                            [disabled]="data.id === versionsData.defaultVersion?.id"
                            (click)="setDefaultVersion(data)"
                            >Set as default</ui-dropdown-item
                        >
                        <ui-dropdown-item
                            [disabled]="versionsDataSource.data.length === 1"
                            (click)="deleteVersion(data)"
                            [uiTooltip]="'A creative set requires at least one version'"
                            [uiTooltipDisabled]="versionsDataSource.data.length !== 1"
                            [uiTooltipHideArrow]="true"
                            uiTooltipTrigger="hover"
                            uiTooltipClass="ml-tooltip"
                            uiTooltipPosition="bottom"
                            [ngClass]="{ 'disabled-button': versionsDataSource.data.length === 1 }"
                            uiTooltipType="discrete"
                            >Delete</ui-dropdown-item
                        >
                    </ui-dropdown>
                </ng-template>
            </ui-list-column>
        </ui-list>
        <ui-dialog-buttons align="right">
            <ui-button
                [loading]="templateData.loading"
                id="interaction-cancel-version-dialog"
                text="Close"
                (click)="close()"></ui-button>
        </ui-dialog-buttons>
    </ng-container>
</ng-container>
