import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { ExternalFontFamilyDto, ExternalFontStyleDto } from '@studio/domain/api/font-manager.types';
import { ExternalFontFamilyComponent } from '@studio/font';
import { FontFamiliesService } from '@studio/common/font-families';
import { debounceTime, map, Subject } from 'rxjs';
import { CreativeConverterStateService } from '../../state/creative-converter.service';

@Component({
    imports: [UIModule, CommonModule, ExternalFontFamilyComponent],
    selector: 'psd-fix-font-panel',
    templateUrl: './psd-fix-font-panel.component.html',
    styleUrls: ['./psd-fix-font-panel.component.scss']
})
export class PsdFixFontPanelComponent {
    private creativeConverterStateService = inject(CreativeConverterStateService);
    private fontFamiliesService = inject(FontFamiliesService);
    private uiNotificationService = inject(UINotificationService);

    layers = toSignal(this.creativeConverterStateService.psdLayers$, { initialValue: [] });

    fontToFix = toSignal(this.creativeConverterStateService.fontToFix$);
    applyToAll = toSignal(this.creativeConverterStateService.applyToAll$);

    isPanelOpen = computed(() => !!this.fontToFix());

    externalFonts = toSignal(this.fontFamiliesService.externalFonts$);

    loadingFonts = toSignal(this.fontFamiliesService.loaded$.pipe(map(loaded => !loaded)));

    private _searchTerm$ = new Subject<string>();
    private debouncedSearchTerm = toSignal(this._searchTerm$.pipe(debounceTime(400)));

    private importedFonts = toSignal(this.fontFamiliesService.importedExternalFonts$);
    private selectedStyle = signal<ExternalFontStyleDto | undefined>(undefined);

    constructor() {
        effect(
            () => {
                const fontToFix = this.fontToFix();
                const searchTerm = this.debouncedSearchTerm();
                if (searchTerm) {
                    this.fontFamiliesService.searchExternalFonts(searchTerm);
                } else if (fontToFix) {
                    this.fontFamiliesService.searchExternalFonts(fontToFix);
                } else {
                    this.fontFamiliesService.resetExternalFonts();
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                const fontToFix = this.fontToFix();
                const importedFonts = this.importedFonts();
                const selectedStyle = this.selectedStyle();
                if (!importedFonts?.length || !selectedStyle) {
                    return;
                }

                const fontFamily = importedFonts.at(0);
                const fontStyle = fontFamily?.fontStyles.find(
                    style =>
                        style.weight === selectedStyle.weight && style.italic === selectedStyle.italic
                );

                if (!fontFamily || !fontStyle) {
                    return;
                }

                this.creativeConverterStateService.fixFontWith(fontFamily, fontStyle);
                if (fontToFix) {
                    this.notifyFix(fontFamily.name, fontToFix);
                }
                this.selectedStyle.set(undefined);
                this._searchTerm$.next('');
            },
            { allowSignalWrites: true }
        );
    }

    externalFontFamilySelected(
        externalFamily: ExternalFontFamilyDto,
        style: ExternalFontStyleDto
    ): void {
        this.fontFamiliesService.importExternalFonts([externalFamily]);
        this.selectedStyle.set(style);
    }

    externalFontStyleSelected(
        externalFamily: ExternalFontFamilyDto,
        style: ExternalFontStyleDto
    ): void {
        const fontFamilyToImport: ExternalFontFamilyDto = {
            ...externalFamily,
            styles: [style]
        };
        this.fontFamiliesService.importExternalFonts([fontFamilyToImport]);
        this.selectedStyle.set(style);
    }

    searchTermChanged(newSearchTerm: string): void {
        this._searchTerm$.next(newSearchTerm);
    }

    closePanel(): void {
        this.creativeConverterStateService.resetFontToFix();
    }

    applyToAllChanged(newValue: boolean): void {
        this.creativeConverterStateService.setApplyToAll(newValue);
    }

    private notifyFix(newFontFamily: string, fontToFix: string): void {
        const messsage = `"${newFontFamily}" font was applied to the "${fontToFix}" layer.`;
        this.uiNotificationService.open(messsage, {
            placement: 'top',
            type: 'success',
            autoCloseDelay: 5000
        });
    }
}
