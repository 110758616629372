import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { concatLatestFrom } from '@ngrx/operators';
import { ICampaignStatus, ListDisplayCampaignsItem } from '@studio/domain/campaigns';
import { map } from 'rxjs';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import { isCreativePublishable } from '../../display-campaign/state/display-campaign.utils';
import { DotDotDotPipe } from '../../pipes/dotdotdot.pipe';
import { EnvironmentService } from '../../services/environment.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    imports: [CommonModule, UIModule, DotDotDotPipe],
    selector: 'creative-meta-rocket',
    templateUrl: './creative-meta-rocket.component.html',
    styleUrls: ['./creative-meta-rocket.component.scss']
})
export class CreativeMetaRocketComponent {
    private environmentService = inject(EnvironmentService);
    private displayCampaignService = inject(DisplayCampaignService);
    private navigationService = inject(NavigationService);

    creative = input<ICreative>();

    inTP$ = this.environmentService.activePage$.pipe(map(activePage => activePage === 'TP'));

    loaded$ = this.displayCampaignService.pushingChangesOnCampaignIds$.pipe(
        concatLatestFrom(() => this.displayCampaignService.campaignsStatusList$),
        map(([campaignIds, campaigns]) => this.isLoaded(campaignIds, campaigns))
    );

    campaignsStatuses = computed(() => this.computeCampaignsStatuses());
    isPublishable = computed(() => this.computeIsPublishable());

    private campaignsList = toSignal(this.displayCampaignService.campaignsList$, { initialValue: [] });
    private listDisplyCampaignsForCreative = computed(() =>
        this.computeListDisplayCampaignsForCreative()
    );
    private campaignsStatus = toSignal(this.displayCampaignService.campaignsStatusList$, {
        initialValue: []
    });

    publishChanges(event?: MouseEvent): void {
        event?.stopPropagation();
        const creative = this.creative();
        if (!creative) {
            return;
        }

        this.displayCampaignService.pushChangesPrompt([creative]);
    }

    openCampaign(campaignId: string): void {
        this.navigationService.openCampaign(campaignId);
    }

    private computeCampaignsStatuses(): ICampaignStatus[] {
        const creative = this.creative();
        const campaignsStatus = this.campaignsStatus();
        if (!creative || !campaignsStatus.length) {
            return [];
        }

        return campaignsStatus.filter(({ creatives }) =>
            creatives.some(({ creativeId }) => creativeId === creative.id)
        );
    }

    private computeListDisplayCampaignsForCreative(): ListDisplayCampaignsItem | undefined {
        const creative = this.creative();
        if (!creative) {
            return;
        }

        return this.campaignsList().find(({ creativeId }) => creativeId === creative.id);
    }

    private computeIsPublishable(): boolean {
        const creative = this.creative();
        if (!creative) {
            return false;
        }
        const connectedCampaigns = this.listDisplyCampaignsForCreative();
        if (!connectedCampaigns) {
            return false;
        }
        const campaignsStatus = this.campaignsStatus();
        if (!campaignsStatus.length) {
            return false;
        }
        return isCreativePublishable(creative, [connectedCampaigns], campaignsStatus);
    }

    private isLoaded(campaignIds: string[], campaigns: ICampaignStatus[]): boolean {
        const creative = this.creative();
        if (!creative) {
            return false;
        }
        if (!campaignIds.length) {
            return true;
        }

        for (const campaignId of campaignIds) {
            if (!this.listDisplyCampaignsForCreative()?.connectedCampaigns.includes(campaignId)) {
                continue;
            }
            const campaignStatus = campaigns.find(({ id }) => campaignId === id);
            if (!campaignStatus) {
                continue;
            }
            const creativeInCampaign = campaignStatus.creatives.find(
                ({ creativeId }) => creativeId === creative.id
            );
            if (creativeInCampaign?.checksum !== creative.checksum) {
                return false;
            }
        }
        return true;
    }
}
