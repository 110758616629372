import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isImageNode } from '@creative/nodes/helpers';
import { ImageSizeMode } from '@domain/image';
import { IImageElementDataNode, OneOfElementDataNodes } from '@domain/nodes';
import { getDynamicPropertyOfElement } from '@studio/utils/element.utils';
import { isCroppedImage } from '@studio/utils/media';
import { filter } from 'rxjs/operators';
import { EditorStateService } from '../../../services/editor-state.service';
import { ElementSelectionService } from '../../../services/element-selection.service';
import { PropertiesService } from '../../properties.service';

@Component({
    selector: 'fit-options',
    templateUrl: './fit-options.component.html',
    styleUrls: ['fit-options.component.scss', '../../common.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FitOptionsComponent implements OnInit {
    @Input() sizeMode: ImageSizeMode | 'mixed';
    @Output() sizeModeChanged = new EventEmitter<ImageSizeMode>();

    classEmptyState = false;
    ImageSizeMode = ImageSizeMode;
    disableCropping = false;

    private changeDetector = inject(ChangeDetectorRef);
    private propertiesService = inject(PropertiesService);
    private elementSelectionService = inject(ElementSelectionService);
    private editorStateService = inject(EditorStateService);
    private destroyRef = inject(DestroyRef);

    constructor() {
        this.elementSelectionService.change$
            .pipe(
                takeUntilDestroyed(),
                filter(({ elements }) => elements.some(isImageNode))
            )
            .subscribe(
                ({ elements }) =>
                    (this.disableCropping = elements.some(element => this.shouldDisableCrop(element)))
            );

        this.propertiesService.dataElementChange$
            .pipe(takeUntilDestroyed(), filter(isImageNode))
            .subscribe(imageElement => {
                this.replaceCrop(imageElement);
            });
    }

    ngOnInit(): void {
        this.propertiesService
            .observeDataElementOrStateChange()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(({ element }) => {
                if (isImageNode(element)) {
                    this.sizeMode = element.imageSettings.sizeMode;
                    this.changeDetector.detectChanges();
                }
                this.classEmptyState = this.propertiesService.stateValueIsUndefined('imageSettings');
            });
    }

    setSizeMode(option: ImageSizeMode): void {
        this.sizeModeChanged.emit(option);
    }

    private shouldDisableCrop(element: OneOfElementDataNodes): boolean {
        const creativesetElement = this.editorStateService.getElementById(element.id);
        return !!getDynamicPropertyOfElement(creativesetElement);
    }

    private replaceCrop(croppedImage: IImageElementDataNode): void {
        this.disableCropping = this.shouldDisableCrop(croppedImage);
        if (this.disableCropping && isCroppedImage(croppedImage)) {
            this.setSizeMode(ImageSizeMode.Fit);
        }
    }
}
