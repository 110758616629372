import { LibraryKind } from '@domain/media-library';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    IDesignViewAnimationSettings,
    IDesignViewSettingValue,
    IDesignViewSettings,
    IIgnoredWarningValue,
    IIgnoredWarnings,
    IManageViewSettingValue,
    IManageViewSettings,
    ISharedSettingValue,
    ISharedSettings,
    KeyOfAnimationSettings
} from './user-settings.model';

export const init = createAction('[User settings] init', props<{ creativeSetId: string }>());

export const setDesignViewSetting = createAction(
    '[User Settings] Set Design View Setting',
    props<{ key: keyof IDesignViewSettings; value: IDesignViewSettingValue }>()
);

export const setManageViewSetting = createAction(
    '[User Settings] Set Manage View Setting',
    props<{ key: keyof IManageViewSettings; value: IManageViewSettingValue }>()
);
export const setIgnoreWarnings = createAction(
    '[User Settings] Set Ignore Warnings',
    props<{ key: keyof IIgnoredWarnings; value: IIgnoredWarningValue }>()
);

export const toggleTranslationPageLayout = createAction(
    '[TranslationPage] Toggle TranslationPage Layout',
    props<{ direction?: 'left' | 'right' }>()
);

export const toggleTranslationPageTab = createAction(
    '[TranslationPage] Toggle TranslationPage tab',
    props<{ tab: 'content' | 'styling' }>()
);

export const toggleTranslationPageShowAllElements = createAction(
    '[TranslationPage] Toggle TranslationPage showAllElements'
);

export const setAnimationSetting = createAction(
    '[User Settings] Set Animation Setting',
    props<{ key: keyof IDesignViewAnimationSettings; value: KeyOfAnimationSettings }>()
);

export const setSharedSetting = createAction(
    '[User Settings] Set Other Setting',
    props<{ key: keyof ISharedSettings; value: ISharedSettingValue }>()
);

export const userSettingsMediaLibrayActions = createActionGroup({
    source: 'UserSettings-MediaLibrary',
    events: {
        pin: emptyProps(),
        unpin: emptyProps(),
        open: props<{ kind: LibraryKind }>(),
        'selected feed id': props<{ selectedFeedId: string }>()
    }
});
