@if (campaignsStatuses(); as statuses) {
    @if (statuses.length) {
        <div
            class="ad-wrapper"
            [class.hidden]="statuses.length === 0">
            @if (loaded$ | async) {
                @if (isPublishable()) {
                    <span
                        id="creative-push-changes"
                        class="ad-stats"
                        [uiTooltip]="'Push changes'"
                        [uiTooltipDisabled]="!!(inTP$ | async)"
                        (click)="publishChanges($event)">
                        <ui-svg-icon
                            class="ad-icon active"
                            icon="campaigns-s"></ui-svg-icon>
                        <span class="ad-count">{{ statuses.length }}</span>
                    </span>
                } @else {
                    <span
                        id="interaction-creative-open-campaigns"
                        class="ad-stats"
                        [uiDropdownTarget]="menu"
                        [uiTooltip]="'Open campaigns'"
                        [uiTooltipDisabled]="!!(inTP$ | async)"
                        (click)="$event.stopPropagation()">
                        <ui-svg-icon
                            class="ad-icon"
                            icon="campaigns-s"></ui-svg-icon>
                        <span class="ad-count">{{ statuses.length }}</span>
                    </span>
                }
            } @else {
                <div class="ad-loader">
                    <ui-loader></ui-loader>
                </div>
            }
        </div>
        <ui-dropdown
            #menu
            [disabled]="!!(inTP$ | async)"
            type="menu"
            [offset]="{ x: 0, y: 10 }">
            <ui-dropdown-item [disabled]="true">Open Campaigns</ui-dropdown-item>
            @for (campaign of statuses; track campaign.id) {
                <ui-dropdown-item
                    svgIcon="display-campaign"
                    [uiTooltip]="campaign.name"
                    (click)="openCampaign(campaign.id)">
                    {{ campaign.name | dotdotdot: 15 }}
                </ui-dropdown-item>
            }
        </ui-dropdown>
    }
}
