import { Injectable } from '@angular/core';
import { DuplicateCreativeResult, ListHubServerToClientEvent } from '@domain/signalr';
import { BrandService } from '@studio/common/brand';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { SignalRHub } from '../../../shared/services/signalr-hub';
import { UserService } from '../../../shared/user/state/user.service';

@Injectable({
    providedIn: 'root'
})
export class ListHubService extends SignalRHub<ListHubServerToClientEvent> {
    duplicationResult$: Observable<DuplicateCreativeResult>;

    constructor(
        protected readonly userService: UserService,
        protected readonly brandService: BrandService,
        protected readonly authService: AuthService
    ) {
        super(
            userService,
            `${environment.origins.listService}/hubs/creativeSets`,
            brandService,
            authService
        );
    }

    destroy(): void {
        this.connection.stop();
    }

    subscribeToNotification(actionCorrelationId: string, brandId: string): Promise<void> {
        return this.connection.invoke<void>('SubscribeToNotification', {
            brandId,
            actionCorrelationId
        });
    }

    protected onConnectionReady(): void {
        this.duplicationResult$ = this.on('SizesAndVersionsIntoNewDuplicationCompleted');
    }
}
