import { Component, ElementRef, inject, OnDestroy, OnInit, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UIDialogComponent } from '@bannerflow/ui';
import { take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';

const FM_READY_MESSAGE = 'fm-ready';
const TOKEN_REFRESH_TIME = 30 * 60 * 1000;

@Component({
    selector: 'font-manager',
    templateUrl: 'font-manager.component.html',
    styleUrls: ['font-manager.component.scss'],
    standalone: false
})
export class FontManagerComponent implements OnInit, OnDestroy {
    private authService = inject(AuthService);
    private dialogComponent = inject(UIDialogComponent);
    private sanitizer = inject(DomSanitizer);

    private iframe = viewChild<ElementRef<HTMLIFrameElement>>('iframe');

    private accessToken = toSignal(this.authService.getAccessToken(), { initialValue: '' });

    url: SafeUrl;

    private intervalId: number;

    ngOnInit(): void {
        const brandId = this.dialogComponent.config.data?.brandId;
        if (!brandId) {
            throw new Error('Brand ID not set');
        }
        const unsanitizedUrl = `${environment.origins.fontManager}/in-frame/${brandId}`;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(unsanitizedUrl);

        const handleFMReady = (event: MessageEvent): void => {
            if (event.origin !== environment.origins.fontManager) {
                return;
            }

            const data = (event as MessageEvent).data;
            if (data === FM_READY_MESSAGE) {
                const token = this.accessToken();
                this.sendTokenToFM(token);
                window.removeEventListener('message', handleFMReady);
            }
        };
        window.addEventListener('message', handleFMReady);

        // Update token
        this.intervalId = window.setInterval(() => {
            this.authService
                .getAccessToken()
                .pipe(take(1))
                .subscribe(accessToken => {
                    this.sendTokenToFM(accessToken);
                });
        }, TOKEN_REFRESH_TIME);
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            window.clearInterval(this.intervalId);
        }
    }

    private sendTokenToFM(token: string): void {
        const iframe = this.iframe();
        iframe?.nativeElement.contentWindow?.postMessage({ token }, environment.origins.fontManager);
    }
}
