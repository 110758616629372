import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import { UIModule } from '@bannerflow/ui';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UserService } from '../../../shared/user/state/user.service';
import { AddToCampaignDropdownComponent } from '../add-to-campaign-dropdown/add-to-campaign-dropdown.component';
import { CampaignsDropdownComponent } from '../campaigns-dropdown/campaigns-dropdown.component';
import { EditCreativeService } from '../services/edit-creative.service';
import { TileSelectService } from '../services/tile-select.service';

type CampaignActionState = 'list' | 'push' | 'create';

@Component({
    imports: [CommonModule, UIModule, AddToCampaignDropdownComponent, CampaignsDropdownComponent],
    selector: 'campaign-actions',
    templateUrl: './campaign-actions.component.html',
    styleUrls: ['./campaign-actions.component.scss']
})
export class CampaignActionsComponent implements OnInit {
    private tileSelectService = inject(TileSelectService);
    private navigationService = inject(NavigationService);
    private displayCampaignService = inject(DisplayCampaignService);
    private editCreativeService = inject(EditCreativeService);
    private userService = inject(UserService);

    numberOfCampaigns$ = this.displayCampaignService.campaignIds$.pipe(
        map(campaignIds => campaignIds.length)
    );

    isUpdatingChecksums$ = this.editCreativeService.isUpdatingChecksums$;
    isPublishing$ = this.displayCampaignService.isPublishing$;

    state$: Observable<CampaignActionState | undefined> = merge(
        this.displayCampaignService.campaignsList$,
        this.displayCampaignService.campaignsStatusList$,
        this.editCreativeService.updatedCreativeTargetUrl$,
        this.displayCampaignService.hasConnectedCampaigns$
    ).pipe(
        concatLatestFrom(() => [
            this.displayCampaignService.hasConnectedCampaigns$,
            this.displayCampaignService.hasChangesToPublish$,
            this.displayCampaignService.isPublishing$
        ]),
        map(([_, hasConnectedCampaigns, hasChangesToPublish, isPublishing]) => {
            if (!hasConnectedCampaigns) {
                return 'create';
            } else if (hasChangesToPublish) {
                return 'push';
            } else if (!isPublishing) {
                return 'list';
            }
        })
    );

    hasSocialCampaignPermissions: boolean;

    async ngOnInit(): Promise<void> {
        this.hasSocialCampaignPermissions =
            await this.userService.hasPermission('SocialCampaignManager');
    }

    onCreateCampaign(): void {
        if (this.hasSocialCampaignPermissions) {
            return;
        }
        this.navigationService.openCreateCampaign();
    }

    onOpenCampaign(campaignId: string): void {
        this.navigationService.openCampaign(campaignId);
    }

    onPushChanges(): void {
        const creatives = this.tileSelectService.getSelected();
        this.displayCampaignService.pushChangesPrompt(creatives);
    }

    redirectToCreateCampaign(): void {
        this.navigationService.openCreateCampaign();
    }
}
