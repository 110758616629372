import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { SentinelService } from '@bannerflow/sentinel';
import { UIDialogService } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative/creative';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { HeavyAssetMetadata } from '@studio/domain/api/heavy-assets.types';
import { ICampaignStatus, ListDisplayCampaignsItem, PublishStatus } from '@studio/domain/campaigns';
import {
    EventLoggerService,
    PublishChangesEndEvent,
    PublishChangesEvent
} from '@studio/monitoring/events';
import { BrandService } from '@studio/common/brand';
import { deepEqual } from '@studio/utils/utils';
import {
    EMPTY,
    Observable,
    catchError,
    debounce,
    delay,
    filter,
    fromEvent,
    map,
    of,
    pairwise,
    switchMap,
    take,
    throttleTime
} from 'rxjs';
import { PublishChangesDialogComponent } from '../../../pages/manage-view/publish-changes-dialog/publish-changes-dialog.component';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { EnvironmentService } from '../../services/environment.service';
import { HeavyAssetsService } from '../../services/heavy-assets.service';
import * as DisplayCampaignActions from './display-campaign.actions';
import { DisplayCampaignDataService } from './display-campaign.data.service';
import { DisplayCampaignService } from './display-campaign.service';
import { isCreativePublishable } from './display-campaign.utils';

@Injectable()
export class DisplayCampaignEffects {
    private actions$ = inject(Actions);
    private brandService = inject(BrandService);
    private creativeSetDataService = inject(CreativesetDataService);
    private displayCampaignDataService = inject(DisplayCampaignDataService);
    private displayCampaignService = inject(DisplayCampaignService);
    private environmentService = inject(EnvironmentService);
    private eventLoggerService = inject(EventLoggerService);
    private ffFeatureFlagService = inject(FFFeatureFlagsService);
    private heavyAssetsService = inject(HeavyAssetsService);
    private sentinelService = inject(SentinelService);
    private uiDialogService = inject(UIDialogService);

    private accoundSlug = toSignal(this.brandService.accountSlug$);
    private brandSlug = toSignal(this.brandService.slug$);

    loadCreativeset$ = createEffect(() => {
        return this.creativeSetDataService.creativeset$.pipe(
            debounce(() => this.brandService.loaded$.pipe(filter(loaded => !!loaded))),
            filter(creativeset => !!creativeset && !this.environmentService.inShowcaseMode),
            map(creativeset => {
                return DisplayCampaignActions.StudioListCampaigns.loadCampaignsList({
                    creativeSetId: creativeset.id
                });
            })
        );
    });

    loadCampaignStatusesAfterList$ = createEffect(() => {
        return this.displayCampaignService.campaignsList$.pipe(
            pairwise(),
            filter(([previousValue, currentValue]) => !deepEqual(previousValue, currentValue)),
            switchMap(([, campaignsList]) => {
                const campaignIds = Array.from(
                    new Set(campaignsList.flatMap(({ connectedCampaigns }) => [...connectedCampaigns]))
                );

                const validCampaignIds = campaignIds.filter(Boolean);
                if (!validCampaignIds.length) {
                    return EMPTY;
                }

                return of(
                    DisplayCampaignActions.loadCampaignsStatus({ campaignIds: validCampaignIds })
                );
            })
        );
    });

    loadCampaignsStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.loadCampaignsStatus),
            filter(({ campaignIds }) => !!campaignIds?.length),
            switchMap(({ campaignIds, polling }) => {
                const accountSlug = this.accoundSlug();
                const slug = this.brandSlug();
                if (!accountSlug || !slug) {
                    return of(
                        DisplayCampaignActions.loadCampaignsStatusFailure({
                            error: new Error('Brand not loaded?')
                        })
                    );
                }
                return this.displayCampaignDataService
                    .loadCampaignStatuses(campaignIds, accountSlug, slug)
                    .pipe(
                        switchMap(campaignPublishStatus =>
                            of(
                                DisplayCampaignActions.loadCampaignsStatusSuccess({
                                    campaignPublishStatus,
                                    polling
                                })
                            )
                        ),
                        catchError(error =>
                            of(DisplayCampaignActions.loadCampaignsStatusFailure({ error }))
                        )
                    );
            })
        );
    });

    pushChangesPrompt$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChangesPrompt),
            concatLatestFrom(() => [
                this.displayCampaignService.campaignsStatusList$,
                this.displayCampaignService.campaignsList$,
                this.displayCampaignService.loaded$
            ]),
            filter(([_actions, _campaignPublishStatus, _campaignsList, loaded]) => !!loaded),
            switchMap(([{ creatives }, campaignPublishStatus, campaignsList]) => {
                const updatedCreatives = this.getUpdatedCreatives(creatives);
                const publishableCreatives = this.getPublishableCreatives(
                    updatedCreatives,
                    campaignsList,
                    campaignPublishStatus
                );
                if (!publishableCreatives.length) {
                    return EMPTY;
                }
                const filteredCampaigns = this.getFilteredCampaigns(
                    publishableCreatives,
                    campaignPublishStatus
                );

                if (this.ffFeatureFlagService.isEnabled('Studio-HeavyAssetCheck')) {
                    return this.heavyAssetsService
                        .checkHeavyAssets(publishableCreatives.map(creative => creative.id))
                        .pipe(
                            switchMap(heavyAssetMetadata => {
                                return this.openDialogAndHandleResponse(
                                    publishableCreatives,
                                    filteredCampaigns,
                                    heavyAssetMetadata
                                );
                            })
                        );
                }

                return this.openDialogAndHandleResponse(
                    publishableCreatives,
                    filteredCampaigns,
                    undefined
                );
            }),
            switchMap(({ confirmed, creatives, campaigns }) => {
                if (!confirmed) {
                    return EMPTY;
                }

                return of(DisplayCampaignActions.pushChanges({ creatives, campaigns }));
            })
        );
    });

    pushChanges$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChanges),
            concatLatestFrom(() => [
                this.displayCampaignService.campaignsStatusList$,
                this.displayCampaignService.pushingChangesOnCampaignIds$
            ]),
            filter(
                ([_actions, _campaignPublishStatus, pushingChangesOnCampaigns]) =>
                    !!pushingChangesOnCampaigns.length
            ),
            switchMap(([{ creatives }]) => {
                this.eventLoggerService.log(new PublishChangesEvent());
                this.sentinelService.trackPageAction('Published Creatives', creatives.length);
                return this.displayCampaignDataService
                    .pushChanges(this.creativeSetDataService.creativeset.id, creatives)
                    .pipe(
                        map(({ failures }) =>
                            failures.length
                                ? DisplayCampaignActions.pushChangesFailure({ error: failures })
                                : DisplayCampaignActions.pushChangesSuccess({ creatives })
                        )
                    );
            })
        );
    });

    pushChangesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChangesSuccess),
            concatLatestFrom(() => this.displayCampaignService.pushingChangesOnCampaignIds$),
            switchMap(([{ creatives }, campaignIds]) => {
                if (!campaignIds.length) {
                    this.eventLoggerService.log(new PublishChangesEndEvent());
                    return EMPTY;
                }

                return of(
                    DisplayCampaignActions.loadCampaignsStatus({
                        campaignIds,
                        polling: {
                            creatives,
                            intervalIndex: 1,
                            lastPoll: Date.now()
                        }
                    })
                );
            })
        );
    });

    pollStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.loadCampaignsStatusSuccess),
            filter(({ polling }) => !!polling),
            concatLatestFrom(() => this.displayCampaignService.pushingChangesOnCampaignIds$),
            switchMap(([{ polling }, pushingChangesOnCampaignIds]) => {
                if (
                    !polling?.creatives ||
                    !pushingChangesOnCampaignIds.length ||
                    polling.intervalIndex > 30
                ) {
                    return EMPTY;
                }
                const delayTime = Math.min(2000, Math.pow(2, polling.intervalIndex) * 300);
                return of(
                    DisplayCampaignActions.loadCampaignsStatus({
                        polling: {
                            creatives: polling.creatives,
                            intervalIndex: polling.intervalIndex + 1,
                            lastPoll: Date.now()
                        },
                        campaignIds: pushingChangesOnCampaignIds
                    })
                ).pipe(delay(delayTime));
            })
        );
    });

    tabVisibilityChange$ = createEffect(() => {
        return fromEvent(document, 'visibilitychange').pipe(
            filter(() => !document.hidden),
            throttleTime(5000),
            filter(() => !this.environmentService.inShowcaseMode),
            map(() => {
                const creativeSetId = this.creativeSetDataService.creativeset.id;
                return DisplayCampaignActions.StudioListCampaigns.loadCampaignsList({ creativeSetId });
            })
        );
    });

    loadCampaignsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.StudioListCampaigns.loadCampaignsList),
            switchMap(({ creativeSetId }) => {
                return this.displayCampaignDataService.getCampaignsConnectedTo(creativeSetId).pipe(
                    switchMap(connectedCampaigns =>
                        of(
                            DisplayCampaignActions.StudioListCampaigns.loadCampaignsListSuccess({
                                connectedCampaigns
                            })
                        )
                    ),
                    catchError(error =>
                        of(
                            DisplayCampaignActions.StudioListCampaigns.loadCampaignsListFailure({
                                error
                            })
                        )
                    )
                );
            })
        );
    });

    private getUpdatedCreatives(creatives: ICreative[]): ICreative[] {
        return creatives.length ? creatives : this.creativeSetDataService.creativeset.creatives;
    }

    private getPublishableCreatives(
        creatives: ICreative[],
        campaignsList: ListDisplayCampaignsItem[],
        campaignPublishStatus: ICampaignStatus[]
    ): ICreative[] {
        return creatives.filter(creative =>
            isCreativePublishable(creative, campaignsList, campaignPublishStatus)
        );
    }
    private getFilteredCampaigns(
        publishableCreatives: ICreative[],
        campaignPublishStatus: ICampaignStatus[]
    ): ICampaignStatus[] {
        const publishableCreativeIds = publishableCreatives.map(({ id }) => id);
        return campaignPublishStatus.filter(
            ({ creatives, status }) =>
                creatives.some(({ creativeId }) => publishableCreativeIds.includes(creativeId)) &&
                status !== PublishStatus.Publishing &&
                status !== PublishStatus.NotPublished
        );
    }
    private openDialogAndHandleResponse(
        publishableCreatives: ICreative[],
        filteredCampaigns: ICampaignStatus[],
        heavyAssetMetadata: HeavyAssetMetadata[] | undefined
    ): Observable<{
        confirmed: boolean;
        creatives: ICreative[];
        campaigns: ICampaignStatus[];
    }> {
        const dialog = this.uiDialogService.openComponent(PublishChangesDialogComponent, {
            headerText: 'Push changes to campaign',
            theme: 'default',
            width: '700px',
            data: { creatives: publishableCreatives, campaigns: filteredCampaigns, heavyAssetMetadata }
        });
        return dialog.afterClose().pipe(
            take(1),
            map(() => ({
                confirmed: !!dialog.config.data.confirmed,
                creatives: publishableCreatives,
                campaigns: filteredCampaigns
            }))
        );
    }
}
