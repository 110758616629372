import { Injectable } from '@angular/core';
import { Color } from '@creative/color';
import { CreativeDataNode } from '@creative/nodes';
import { isGroupDataNode, isVideoNode, isWidgetElement } from '@creative/nodes/helpers';
import { Renderer } from '@creative/renderer';
import { deserializeBrandlibraryElementProperties } from '@creative/serialization';
import { visitOneNode } from '@creative/visitor';
import { diInject, diInjectable, runWithDiScope } from '@di/di';
import { Token } from '@di/di.token';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IRenderer } from '@domain/creative/renderer.header';
import { IElement } from '@domain/creativeset/element';
import { IFontFamily } from '@domain/font-families';
import { OneOfElementDataNodes } from '@domain/nodes';
import { FontFamiliesService } from '@studio/common/font-families';
import { uuidv4 } from '@studio/utils/id';
import { firstValueFrom } from 'rxjs';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { NodeCreatorService } from '../services/data-node-creator.service';

/**
 * Service that handles rendering of elements outside of the
 * creative itself, for example in the brand library
 */
@Injectable()
export class ElementRenderingService {
    window: Window;
    renderer: IRenderer;
    private fontFamilies: IFontFamily[];
    private readonly diScope = uuidv4();

    constructor(
        private fontFamiliesService: FontFamiliesService,
        private nodeCreator: NodeCreatorService,
        private environmentService: EnvironmentService
    ) {
        firstValueFrom(this.fontFamiliesService.fontFamilies$).then(fontFamilies => {
            this.fontFamilies = fontFamilies;
        });
    }

    createElement(
        element: IElement,
        excludeAnimations = false,
        dataOverride?: Partial<OneOfElementDataNodes>
    ): OneOfElementDataNodes {
        this.initializeRenderer();

        const dataNode = this.nodeCreator.create(element.type, {
            kind: element.type,
            ...dataOverride
        });

        if (isGroupDataNode(dataNode)) {
            throw new Error('Illegal group node creation.');
        }

        /**
         * BL elements doesn't need a name.
         * Also conflicts with e2e tests
         * */
        dataNode.name = '';

        this.setData(element, dataNode, excludeAnimations);

        // Applies the scope id to the renderer in order to apply styles
        this.renderer.setScopeId_m(`scope-${dataNode.id}`);

        runWithDiScope(this.diScope, () => {
            if (isVideoNode(dataNode)) {
                this.renderer.visitVideo_m(dataNode, true);
            } else {
                visitOneNode(dataNode, this.renderer);
            }
        });

        return dataNode;
    }

    private initializeRenderer(): void {
        if (this.renderer) {
            return;
        }

        const creativeFill = new Color();
        const creativeDocument = new CreativeDataNode({
            id: uuidv4(),
            width: 800,
            height: 800,
            fill: creativeFill
        });
        const env = {
            ...this.environmentService.env,
            MODE: CreativeMode.DesignView
        } as ICreativeEnvironment;

        diInjectable(Token.RENDERER, Renderer, this.diScope).withArgs(
            creativeDocument,
            {
                contentWindow: window,
                fontFamilies: this.fontFamilies
            },
            env
        );

        this.renderer = diInject(Token.RENDERER, { scope: this.diScope });

        this.window = window;
        this.renderer.rootElement = this.window.document.createElement('div');
    }

    private setData(element: IElement, data: OneOfElementDataNodes, excludeAnimations = false): void {
        deserializeBrandlibraryElementProperties(
            data,
            element.properties.map(p => p)
        );
        if (excludeAnimations) {
            data.states = [];
            data.animations = [];
        }
    }

    getData(element: IElement): OneOfElementDataNodes {
        const data: OneOfElementDataNodes = {
            kind: element.type
        } as OneOfElementDataNodes;
        deserializeBrandlibraryElementProperties(
            data,
            element.properties.map(p => p)
        );
        return data;
    }

    isStyledElement(element: IElement): boolean {
        if (!isWidgetElement(element) && element.properties) {
            return [...element.properties].some(prop => prop.unit && prop.unit.includes('number'));
        }
        return false;
    }
}
