import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadActions } from '@studio/common/brand';
import { creativeSetActions } from '@studio/common/creativeSet/creativeSet.actions';
import { cloneDeep } from '@studio/utils/clone';
import { EMPTY, of, switchMap, tap } from 'rxjs';
import { VersionsActions } from '../../versions/state/versions.actions';
import { CreativesetDataService } from '../creativeset.data.service';

@Injectable()
export class CreativeSetEffects {
    private actions$ = inject(Actions);
    private creativesetDataService = inject(CreativesetDataService);

    loaded$ = createEffect(() => {
        return this.creativesetDataService.creativeset$.pipe(
            switchMap(creativeSet => {
                if (!creativeSet) {
                    return EMPTY;
                }
                return of(creativeSetActions.loaded({ creativeSetId: creativeSet.id }));
            })
        );
    });

    brandLoaded$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadActions.loadBrandSuccess),
                tap(({ brand }) => {
                    this.creativesetDataService.brand = brand;
                })
            );
        },
        { dispatch: false }
    );

    newVersionSaved$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(VersionsActions.createVersionSuccess),
                tap(({ creatives, versions }) => {
                    this.creativesetDataService.onVersionCreated(versions, creatives);
                })
            );
        },
        { dispatch: false }
    );

    deleteVersionsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(VersionsActions.deleteVersionsSuccess),
                tap(deletedVersions => {
                    this.creativesetDataService.onVersionsDeleted(deletedVersions.versionsIds);
                })
            );
        },
        { dispatch: false }
    );

    updateVersionsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(VersionsActions.updateVersionsSuccess),
                tap(updatedVersions => {
                    const versions = cloneDeep(updatedVersions.versions);
                    this.creativesetDataService.onVersionsUpdated(versions);
                })
            );
        },
        { dispatch: false }
    );

    setDefaultVersionSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(VersionsActions.setDefaultVersionSuccess),
                tap(newDefaultVersion => {
                    this.creativesetDataService.onDefaultVersionUpdated(newDefaultVersion.version.id);
                })
            );
        },
        { dispatch: false }
    );
}
