import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    QueryList,
    ViewChild,
    ViewChildren,
    inject
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { UIDropdownComponent, UIDropdownTargetDirective, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { CreativeSize } from '@domain/creativeset/size';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { hasActiveDesigns, hasDesign } from '@studio/utils/design.utils';
import { isChildOfSelector, isElementDescendantOfElementWithClass } from '@studio/utils/dom-utils';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CreativeListItemComponent } from '../../../shared/creative-list/creative-list-item/creative-list-item.component';
import { CreativeSetShowcaseService } from '../../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import {
    isCreativeInCampaign,
    isCreativePublishable
} from '../../../shared/display-campaign/state/display-campaign.utils';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { StudioRoutingService } from '../../../shared/services/studio-routing.service';
import { WeightService } from '../../../shared/weight-calculation/state/weight.service';
import { AddToCampaignDropdownComponent } from '../add-to-campaign-dropdown/add-to-campaign-dropdown.component';
import { DuplicateCreativeService } from '../duplicate-creative-dialog/duplicate-creative.service';
import { ExportDropdownComponent } from '../export-creative';
import { EditCreativeService } from '../services/edit-creative.service';
import { TileSelectService } from '../services/tile-select.service';
import { StatusDropdownComponent } from '../status-dropdown/status-dropdown.component';
import { ManageViewContextMenuService } from './manage-view-context-menu.service';

@Component({
    imports: [
        CommonModule,
        UIModule,
        PermissionsDirective,
        StatusDropdownComponent,
        ExportDropdownComponent,
        AddToCampaignDropdownComponent
    ],
    selector: 'mv-context-menu',
    templateUrl: `context-menu.component.html`,
    styleUrls: ['./context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MVContextMenuComponent {
    filtersService = inject(FiltersService);
    tileSelectService = inject(TileSelectService);
    private creativesetDataService = inject(CreativesetDataService);
    private creativesetShowcaseService = inject(CreativeSetShowcaseService);
    private displayCampaignService = inject(DisplayCampaignService);
    private duplicationService = inject(DuplicateCreativeService);
    private editCreativeService = inject(EditCreativeService);
    private environmentService = inject(EnvironmentService);
    private manageViewContextMenuService = inject(ManageViewContextMenuService);
    private studioRoutingService = inject(StudioRoutingService);
    private weightService = inject(WeightService);

    @ViewChild('menuTrigger') menuTrigger: UIDropdownTargetDirective;
    @ViewChild('menu') menu: UIDropdownComponent;
    @ViewChildren(CreativeListItemComponent)
    creativeListItemComponent: QueryList<CreativeListItemComponent>;

    keyboardShortcuts = getHotkeysAsKeyValueList(['ManagePage']);
    isMac = navigator.userAgent.includes('Mac');

    oneIsSelected: boolean;
    anyIsSelected: boolean;

    canActivateCreatives: boolean;
    canDeactivateCreatives: boolean;

    canPaste = false;
    canCopy = false;

    selectedCreatives: ICreative[];
    publishDisabled: boolean;
    exportDisabled: boolean;
    calculateWeightDisabled: boolean;
    mobilePreviewDisabled: boolean;
    disableDelete: boolean;
    creativesSelectedText: string;
    deleteQueueText: string;
    duplicateText: string;
    deactivateText: string;
    activateText: string;
    exportText: string;
    calculateWeightQueueText: string;
    publishQueueText: string;
    partOfCampaign: boolean;

    showSelectionOptions: boolean;

    creatives: ICreative[];
    top = 0;
    left = 0;

    addToCampaignDisabled$ = this.tileSelectService.selection$.pipe(
        map(selectedCreatives => {
            const hasCreativesWithDesign =
                selectedCreatives.filter(creative => !!creative.design).length > 0;
            return !hasCreativesWithDesign || !this.hasConnectedCampaigns;
        })
    );

    addToCampaignText$ = this.tileSelectService.selection$.pipe(
        map(selectedCreatives => {
            const creativesWithDesign = selectedCreatives.filter(creative => !!creative.design).length;
            return !creativesWithDesign || !this.hasConnectedCampaigns
                ? 'Add to campaign'
                : `Add to campaign (${creativesWithDesign})`;
        })
    );

    inShowcaseMode$ = this.environmentService.inShowcaseMode$;

    private hasConnectedCampaigns: boolean;

    private campaignsList = toSignal(this.displayCampaignService.campaignsList$, { initialValue: [] });
    private campaigns = toSignal(this.displayCampaignService.campaignsStatusList$, {
        initialValue: []
    });

    constructor() {
        this.displayCampaignService.hasConnectedCampaigns$
            .pipe(takeUntilDestroyed())
            .subscribe(hasConnectedCampaigns => (this.hasConnectedCampaigns = hasConnectedCampaigns));

        this.tileSelectService.selection$
            .pipe(takeUntilDestroyed())
            .subscribe(creatives => (this.selectedCreatives = creatives.filter(c => c.design)));

        this.manageViewContextMenuService.opened$.pipe(takeUntilDestroyed()).subscribe(data => {
            if (data.isOpen) {
                this.openMenu(data.creatives, data.x, data.y, data.showSelectionOptions);
            } else {
                this.closeDropdown();
            }
        });

        fromEvent<PointerEvent>(window, 'contextmenu')
            .pipe(
                filter(() => !this.environmentService.isMobile),
                takeUntilDestroyed()
            )
            .subscribe(event => this.contextMenu(event));

        fromEvent<PointerEvent>(window, 'click')
            .pipe(takeUntilDestroyed())
            .subscribe(event => this.onWindowClick(event));
    }

    // Triggered when menu opens
    toggle(open: boolean): void {
        if (open) {
            this.setMenuState(this.creatives);
        }
    }

    contextMenu(event: PointerEvent): void {
        // Don't prevent comment right click menu
        if (!(event.target && isChildOfSelector(event.target as HTMLElement, 'cs-threads'))) {
            event.preventDefault();
        }

        if (
            event.button === 2 &&
            (isElementDescendantOfElementWithClass(event.target, 'ui-popover') ||
                isElementDescendantOfElementWithClass(event.target, 'ui-popover-backdrop') ||
                isElementDescendantOfElementWithClass(event.target, 'cdk-overlay-container'))
        ) {
            return;
        }

        this.close();

        if (!this.menuTrigger.dropdownOpen) {
            this.openMenu(this.tileSelectService.getSelected(), event.pageX, event.pageY);
        }
    }

    onWindowClick(event: PointerEvent): void {
        if (!isElementDescendantOfElementWithClass(event.target, 'ui-popover')) {
            this.close();
        }
    }

    openMenu(creatives: ICreative[], x: number, y: number, showSelectionOptions = true): void {
        this.creatives = creatives;
        this.showSelectionOptions = showSelectionOptions;

        this.top = y;
        this.left = x;

        this.menuTrigger.openDropdown();
    }

    async overrideTargetUrl(): Promise<void> {
        await this.editCreativeService.overrideTargetUrl(this.creatives);
    }

    selectAll(): void {
        this.tileSelectService.selectAllCreatives();
        this.close();
    }

    deselectAll(): void {
        this.tileSelectService.deselectAllCreatives();
        this.close();
    }

    isAnyPublishable(creatives: ICreative[]): boolean {
        return creatives.some(creative =>
            isCreativeInCampaign(creative, this.campaignsList(), this.campaigns())
        );
    }

    editDesign(event: MouseEvent): void {
        this.studioRoutingService.navigateToCreative(this.tileSelectService.getSelected()[0], event);
    }

    isExportable(creatives: ICreative[]): boolean {
        return creatives.some(hasDesign);
    }

    isWeightable(creatives: ICreative[]): boolean {
        const count = this.weightService.getWeightableCreatives(creatives).length;
        return count > 0;
    }

    publishChanges(): void {
        const selectedCreatives = this.tileSelectService.getSelected();
        this.displayCampaignService.pushChangesPrompt(selectedCreatives);
    }

    showMobilePreviewModal(): void {
        this.creativesetShowcaseService.openQRLinkModal();
    }

    showShowcaseMobilePreviewModal(): void {
        this.creativesetShowcaseService.openShowcaseLinkAsQR();
    }

    async delete(): Promise<void> {
        if (this.partOfCampaign) {
            return;
        }
        this.close();
        const isCreativesConnectedToCampaigns = this.creatives.some(creative =>
            this.campaignsList().some(
                ({ connectedCampaigns, creativeId }) =>
                    creativeId === creative.id && !!connectedCampaigns.length
            )
        );
        if (isCreativesConnectedToCampaigns) {
            return;
        }
        await this.editCreativeService.deleteSizes(this.creatives);
    }

    async calculateWeights(): Promise<void> {
        this.close();

        await this.weightService.beginWeightCalculation(this.creatives);
    }

    duplicateSize(duplicateToNew: boolean): void {
        this.duplicationService.openDialog(duplicateToNew);
    }

    duplicateCreatives(): void {
        this.duplicationService.duplicateCreatives(this.creatives);
    }

    deactivateSize(): Promise<void> {
        return this.editCreativeService.deactivateDesign(this.creatives);
    }

    async activateSize(): Promise<void> {
        const creatives = this.creatives.filter(creative => !hasDesign(creative));
        await this.editCreativeService.activateCreatives(creatives);
        this.close();
    }

    copyDesign = (): void => {
        this.editCreativeService.copyDesign();
        this.close();
    };

    pasteDesign = (): void => {
        if (!this.canPaste || !this.editCreativeService.copiedCreative) {
            return;
        }
        this.editCreativeService.pasteDesign();
    };

    close(): void {
        this.manageViewContextMenuService.close();
    }

    closeDropdown(): void {
        this.menuTrigger?.closeDropdown();
        this.showSelectionOptions = false;
    }

    removeAllFilters(): void {
        this.filtersService.clearSizesFilter();
    }

    hideSelected(): void {
        this.filtersService.hideSelected();
    }

    hideOthers(): void {
        this.filtersService.hideOthers();
    }

    private setMenuState(creatives: ICreative[]): void {
        this.oneIsSelected = creatives.length === 1;
        this.anyIsSelected = creatives.length > 0;

        const isSameCreative =
            this.oneIsSelected &&
            creatives.every(
                creative => creative.design === this.editCreativeService?.copiedCreative?.design
            );
        this.canPaste =
            !isSameCreative && this.anyIsSelected && !!this.editCreativeService.copiedCreative;

        this.canCopy = this.oneIsSelected && hasDesign(creatives[0]);

        this.publishDisabled = !this.isAnyPublishable(creatives);
        this.exportDisabled = !this.isExportable(creatives);
        this.calculateWeightDisabled = !this.isWeightable(creatives);
        this.mobilePreviewDisabled = creatives.some(creative => !creative.design);

        const allCreativesForSizes = this.getAllCreativesForSizes(creatives.map(c => c.size));
        this.partOfCampaign = allCreativesForSizes.some(creative =>
            this.campaignsList().some(
                ({ connectedCampaigns, creativeId }) =>
                    creativeId === creative.id && !!connectedCampaigns.length
            )
        );
        this.disableDelete = this.partOfCampaign || !this.anyIsSelected;

        this.canActivateCreatives =
            hasActiveDesigns(this.creativesetDataService.creativeset.creatives) &&
            creatives.some(creative => !hasDesign(creative));
        this.canDeactivateCreatives =
            !this.partOfCampaign && creatives.some(creative => hasDesign(creative));
        this.setTexts(creatives);
    }

    private getAllCreativesForSizes(sizes: CreativeSize[]): ICreative[] {
        return [
            ...this.creativesetDataService.creativeset.creatives.filter(creative =>
                sizes.some(size => creative.size.id === size.id)
            )
        ];
    }

    private setTexts(creatives: ICreative[]): void {
        const count = creatives.length;
        this.creativesSelectedText = count >= 2 ? `(${count})` : '';

        const deleteCount = this.editCreativeService.getUniqueSizeCreatives(creatives).length;
        this.deleteQueueText = this.getCountText(deleteCount, 'Delete size', 'Delete sizes');
        this.duplicateText = this.getCountText(count, 'Duplicate size', 'Duplcate sizes');

        const activeCreatives = this.creatives.filter(creative => hasDesign(creative));
        const deactivateCount = this.editCreativeService.getUniqueSizeCreatives(activeCreatives).length;
        this.deactivateText = this.getCountText(deactivateCount, 'Deactivate size', 'Deactivate sizes');

        const inActiveCreatives = this.creatives.filter(creative => !hasDesign(creative));
        const activateCount = this.editCreativeService.getUniqueSizeCreatives(inActiveCreatives).length;
        this.activateText = this.getCountText(activateCount, 'Activate size', 'Activate sizes');

        this.exportText = this.getCountText(
            activeCreatives.length,
            'Export creative',
            'Export creatives'
        );

        const weightableCount = this.weightService.getWeightableCreatives(this.creatives).length;
        this.calculateWeightQueueText = this.getCountText(
            weightableCount,
            'Calculate weights',
            'Calculate weight'
        );

        const connectedCampaigns = this.campaignsList();
        const publishableCount = creatives.filter(creative =>
            isCreativePublishable(creative, connectedCampaigns, this.campaigns())
        ).length;
        this.publishQueueText = this.getCountText(publishableCount, 'Push changes', 'Push changes');
    }

    private getCountText(count: number, label1: string, label2: string): string {
        return count >= 2 ? `${label2} (${count})` : label1;
    }
}
