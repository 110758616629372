import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    computed,
    inject,
    input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommentService, CommentsModule, ThreadsDirective } from '@bannerflow/comments';
import { UIModule } from '@bannerflow/ui';
import { ListDisplayCampaignsItem } from '@studio/domain/campaigns';
import { ICreative } from '@domain/creativeset/creative';
import { map, take } from 'rxjs/operators';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import { CreativeMetaRocketComponent } from '../../components/creative-meta-rocket/creative-meta-rocket.component';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import { EnvironmentService } from '../../services/environment.service';
import { StudioCommentService } from '../../services/studio-comment.service';

@Component({
    selector: 'creative-list-item-meta',
    imports: [
        CommonModule,
        UIModule,
        PermissionsDirective,
        CommentsModule,
        CreativeMetaRocketComponent
    ],
    templateUrl: './creative-list-item-meta.component.html',
    styleUrls: ['./creative-list-item-meta.component.scss']
})
export class CreativeListItemMetaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    commentService = inject(CommentService);
    private changeDeterctor = inject(ChangeDetectorRef);
    private displayCampaignService = inject(DisplayCampaignService);
    private editCreativeService = inject(EditCreativeService);
    private environmentService = inject(EnvironmentService);
    private studioCommentService = inject(StudioCommentService);

    creative = input<ICreative>();

    @Input() inView: boolean;

    @Output() commentsToggled = new EventEmitter<boolean>();

    @ViewChild('commentsThread') commentsThread?: ThreadsDirective;

    commentsToken: string;
    commentsActive = false;
    inShowcase = false;

    loaded = toSignal(
        this.displayCampaignService.pushingChangesOnCampaignIds$.pipe(
            map(
                campaignIds =>
                    !campaignIds.length ||
                    campaignIds.every(
                        id => !this.listDisplayCampaignsForCreative()?.connectedCampaigns.includes(id)
                    )
            )
        ),
        { initialValue: false }
    );

    private showCommentsTimeout?: number;
    private listDisplayCampaignsForCreative = computed(() =>
        this.computeListDisplayCampaignsForCreative()
    );
    private campaignsList = toSignal(this.displayCampaignService.campaignsList$, { initialValue: [] });

    constructor() {
        this.commentsToken = this.studioCommentService.creativesToken;
        this.inShowcase = this.environmentService.inShowcaseMode;
    }

    ngOnInit(): void {
        if (!this.creative) {
            return;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inView) {
            if (this.inView) {
                if (this.commentsActive) {
                    clearTimeout(this.showCommentsTimeout);
                    this.showCommentsTimeout = window.setTimeout(() => {
                        this.commentsThread!.openThreadOverlay();
                        this.changeDeterctor.detectChanges();
                    }, 750); // Comp must rendered before open the overlay
                }
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.commentsThread?.threadOpenToggle.pipe(take(1)).subscribe((b: boolean) => {
                if (b !== this.commentsActive) {
                    this.commentsActive = b;
                    this.commentsToggled.emit(this.commentsActive);
                    this.changeDeterctor.detectChanges();
                }
            });
        }, 2000);
    }

    ngOnDestroy(): void {
        clearTimeout(this.showCommentsTimeout);
    }

    overrideTargetUrl(): void {
        const creative = this.creative();
        if (!creative) {
            return;
        }
        this.editCreativeService.overrideTargetUrl([creative]);
    }

    private computeListDisplayCampaignsForCreative(): ListDisplayCampaignsItem | undefined {
        const creative = this.creative();
        if (!creative) {
            return;
        }
        return this.campaignsList().find(({ creativeId }) => creativeId === creative.id);
    }
}
