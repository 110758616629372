import { inject, Injectable } from '@angular/core';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService } from '@bannerflow/ui';
import { getGifFrames } from '@creative/gif-export/gif-export';
import { ICreative } from '@domain/creativeset/creative/creative';
import {
    IExportCreativeFrame,
    IExportCreativeItem,
    IExportCreativeRequest,
    IExportSettings,
    IExportVideoCreative,
    IExportVideoRequest
} from '@domain/creativeset/creative/export-creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { StudioFeatureFlags } from '@studio/domain/feature-flags';
import { firstValueFrom } from 'rxjs';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { FileDownloadService } from '../../../../shared/services/filedownload.service';
import { ExportCreativeDataService } from '../export-creative.data.service';
import { CreativeExport } from './export.models';

@Injectable({ providedIn: 'root' })
export class ExportService {
    private creativesetDataService = inject(CreativesetDataService);
    private environmentService = inject(EnvironmentService);
    private exportCreativeDataService = inject(ExportCreativeDataService);
    private featureFlagsService = inject(FFFeatureFlagsService);
    private fileDownloadService = inject(FileDownloadService);
    private uiNotificationService = inject(UINotificationService);

    private downloadIsPending = false;
    private logger = new Logger('ExportService');

    constructor() {
        this.exportCreativeDataService.exportFinished$.subscribe(({ filePath }) => {
            this.fileDownloadService.download(filePath);
        });

        this.exportCreativeDataService.exportFailure$.subscribe(({ failures }) => {
            const message = failures?.length ? 'Could not export creative' : 'Error while exporting';

            this.uiNotificationService.open(message, { type: 'error', autoCloseDelay: 5000 });
        });
    }

    startExport(settings?: IExportSettings): void {
        if (settings) {
            if (
                !this.environmentService.inShowcaseMode ||
                !this.featureFlagsService.isEnabled(StudioFeatureFlags.AllowExportingFromShowcase)
            ) {
                delete settings.email;
            }
            const creativeset = this.creativesetDataService.creativeset;
            if (settings.type === 'mp4') {
                this.startVideoExport(creativeset, settings);
            } else {
                this.startImageExport(creativeset, settings);
            }
        } else {
            this.logger.warn('Cant start export without settings');
        }
    }

    private startImageExport(creativeset: ICreativeset, settings: IExportSettings): Promise<void> {
        if (this.downloadIsPending) {
            return Promise.resolve();
        }
        this.logger.verbose('Starting to export creatives');

        this.uiNotificationService.open(
            'Images are being exported - download will start once they are ready.',
            {
                autoCloseDelay: 5000,
                placement: 'top',
                type: 'success'
            }
        );

        const creatives = settings.creatives;
        const exportCreatives: IExportCreativeItem[] = creatives
            .map(creative => ({
                creativeId: creative.id,
                frames: this.getFramesFromCreative(creative, settings)
            }))
            .filter(e => e.frames.length > 0);

        const loops = settings.loops ?? 0;

        const request: IExportCreativeRequest = {
            type: settings.type,
            quality: settings.quality,
            loops: loops,
            creatives: exportCreatives,
            creativesetId: creativeset.id
        };

        if (settings.email && this.environmentService.inShowcaseMode) {
            if (!this.featureFlagsService.isEnabled(StudioFeatureFlags.AllowExportingFromShowcase)) {
                throw new Error('Export from showcase is disabled');
            }
            return this.exportShowcaseCreative(creativeset.brandId, request, settings.email);
        }

        return this.export(creativeset.brandId, request);
    }

    private async exportShowcaseCreative(
        brandId: string,
        request: IExportCreativeRequest,
        recipientEmail: string
    ): Promise<void> {
        try {
            await firstValueFrom(
                this.exportCreativeDataService.exportShowcaseCreative(brandId, request, recipientEmail)
            );

            this.uiNotificationService.open('You will soon receive an email with your file(s)', {
                placement: 'top',
                type: 'success'
            });
        } catch {
            this.uiNotificationService.open('An error has occurred, try again later', {
                placement: 'top',
                type: 'error'
            });
        }
    }

    private async export(brandId: string, request: IExportCreativeRequest): Promise<void> {
        const creativeExport = new CreativeExport();
        creativeExport.creativesetId = request.creativesetId;

        const res = await firstValueFrom(
            this.exportCreativeDataService.exportCreative(brandId, request)
        );
        if (!res) {
            throw new Error('Error while exporting');
        }

        if (this.featureFlagsService.isEnabled(StudioFeatureFlags.NotificationServiceIntegration)) {
            this.logger.verbose(`Export requested. CorrelationId: ${res.correlationId}. No polling`);
            return;
        }
        this.logger.verbose(`Export requested. CorrelationId: ${res.correlationId}`);
        creativeExport.correlationId = res.correlationId;
        creativeExport.startTime = Date.now();

        this.exportCreativeDataService.checkExportStatus(creativeExport);
    }

    private startVideoExport(creativeset: ICreativeset, settings: IExportSettings): void {
        const request = this.createVideoRequest(creativeset, settings);
        this.exportCreativeDataService.exportCreativeVideo(request);
    }

    private createVideoRequest(
        creativeset: ICreativeset,
        settings: IExportSettings
    ): IExportVideoRequest {
        const videoRequest: IExportVideoRequest = {
            creativeSetId: +creativeset.id,
            creatives: this.createCreativeVideoRequestInput([...settings.creatives], settings.frameRate)
        };
        if (settings.email) {
            videoRequest.email = settings.email;
        }
        return videoRequest;
    }

    private createCreativeVideoRequestInput(
        creatives: ICreative[],
        frameRate = 60
    ): IExportVideoCreative[] {
        const exportCreatives: IExportVideoCreative[] = [];
        for (const creative of creatives) {
            if (!creative.design) {
                continue;
            }
            exportCreatives.push({
                width: creative.size.width,
                height: creative.size.height,
                duration: creative.design.document.duration,
                id: +creative.id,
                fps: frameRate
            });
        }
        return exportCreatives;
    }

    private getFramesFromCreative(
        creative: ICreative,
        settings?: IExportSettings
    ): IExportCreativeFrame[] {
        if (!creative.design?.document) {
            throw new Error(`Can't calculate where image should be captured without provided document`);
        }

        if (settings?.type === 'gif') {
            const gifFrames = getGifFrames(creative.design.document, settings.quality, settings);
            if (gifFrames.length) {
                return gifFrames as IExportCreativeFrame[];
            }
        }
        return [
            {
                time: creative.design.document.getFirstPreloadImageFrame(),
                duration: 4
            }
        ];
    }
}
