import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sort as MatSort, MatSortModule } from '@angular/material/sort';
import {
    InlineEditOnSaveEvent,
    RowEvent,
    UIColumnDef,
    UIDebounce,
    UIModule,
    UINotificationService,
    UITableDataSource
} from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { IVersion } from '@domain/creativeset/version';
import { concatLatestFrom } from '@ngrx/operators';
import {
    ICLVList,
    ICLVListItem,
    IDuplicationResponse
} from '@studio/domain/api/duplicate-creatives.types';
import { Sort } from '@studio/domain/components/duplicate-creatives/duplicate-creatives.types';
import { BrandService } from '@studio/common/brand';
import { BehaviorSubject, filter } from 'rxjs';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { ListHubService } from '../../duplicate-creatives/list-hub.service';
import { DuplicateCreativeStateService } from '../../duplicate-creatives/state/duplicate-creative.service';
import { clearWildcards } from '../../duplicate-creatives/state/duplicate-creative.utils';

interface IListItem extends ICLVListItem {
    disabled: boolean;
}

interface ActiveColumnDef extends UIColumnDef {
    active: boolean;
}
interface Breadcrumb {
    id: string;
    name: string;
}

enum ListItemType {
    Folder = 'Folder',
    CreativeSet = 'CreativeSet'
}

@Component({
    selector: 'creativesets-list',
    templateUrl: './creativesets-list.component.html',
    styleUrls: ['./creativesets-list.component.scss'],
    imports: [UIModule, CommonModule, MatSortModule, CdkTableModule]
})
export class CreativesetsListComponent implements OnDestroy {
    @Input() duplicateToNew: boolean;
    @Input() selectedSizes: CreativeSize[];
    @Input() selectedVersions: IVersion[];
    @Input() disableDuplication: boolean;
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('dialogTable', { static: true }) dialogTable: ElementRef<HTMLDivElement>;

    private uiNotificationService = inject(UINotificationService);
    private brandService = inject(BrandService);
    private creativesetDataService = inject(CreativesetDataService);
    private listHubService = inject(ListHubService);
    private duplicateCreativeStateService = inject(DuplicateCreativeStateService);

    isDuplicating$ = this.duplicateCreativeStateService.isDuplicating$;

    private _matSort = new BehaviorSubject<MatSort>({ active: 'name', direction: 'asc' });
    matSort$ = this._matSort.asObservable();
    private sort: Sort;
    private brandId: string;
    dataSource = new UITableDataSource<IListItem>();
    listItems: IListItem[] = [];
    page = 1;
    totalPages = 1;
    showLoadMore = false;

    targetCreativesetName: string;
    targetItemId?: string;
    targetExternalId?: string;
    targetFolderId?: string;

    displayBreadcrumbs: Breadcrumb[] = [];
    breadcrumbs: Breadcrumb[] = [];

    currentItems: ICLVListItem[] = [];
    creativesetId: string;
    searchTerm: string;
    isPendingRequest: boolean;
    isNewFolder: boolean;
    columnNames: ActiveColumnDef[] = [
        {
            name: 'Name',
            columnDef: 'name',
            isCustom: true,
            active: true,
            sortable: true
        },
        {
            name: 'Created',
            columnDef: 'createdAt',
            width: '20%',
            isCustom: true,
            active: true,
            sortable: true
        }
    ];

    constructor() {
        this.loadCreativeSets();
        this.listHubService.startConnection();
        this.brandService.brandId$.pipe(takeUntilDestroyed()).subscribe(brandId => {
            this.brandId = brandId;
        });
        this.duplicateCreativeStateService.error$
            .pipe(
                takeUntilDestroyed(),
                filter(error => !!error),
                concatLatestFrom(() => this.duplicateCreativeStateService.isDuplicating$)
            )
            .subscribe(([error, isDuplicating]: [any, boolean]) => {
                if (isDuplicating) {
                    this.displayErrorNotification(`Error while duplicating: ${error.statusText}`);
                    return;
                }

                if (error === 'Failed to create new folder') {
                    this.displayErrorNotification(error);
                    return;
                }
                this.displayErrorNotification(`Error while getting items`);
            });
        this.duplicateCreativeStateService.itemsPage$
            .pipe(takeUntilDestroyed())
            .subscribe(({ searchTerm, setsAndFolders }) => {
                if (!this.breadcrumbs.length) {
                    this.searchTerm = searchTerm ?? '';
                }
                this.setItemsFromResponse(setsAndFolders);
            });
        this.duplicateCreativeStateService.duplicationResponse$
            .pipe(takeUntilDestroyed())
            .subscribe(response => {
                if (!response) {
                    return;
                }
                this.displayNotification(response);
                this.onCancel.emit();
            });
        this.duplicateCreativeStateService.loaded$.pipe(takeUntilDestroyed()).subscribe(loaded => {
            this.isPendingRequest = !loaded;
        });

        this.dataSource.data$.pipe(takeUntilDestroyed()).subscribe(listItems => {
            this.listItems = listItems;
        });

        this.creativesetId = this.creativesetDataService.creativeset.id;

        this.matSort$.pipe(takeUntilDestroyed()).subscribe(sort => {
            this.sort = { fieldName: sort.active, order: sort.direction };
            this.loadCreativeSets();
        });
    }

    changeSorting({ active, direction }): void {
        this._matSort.next({ active, direction });
    }

    ngOnDestroy(): void {
        this.duplicateCreativeStateService.clearState();
        this.listHubService.destroy();
    }

    cancel(): void {
        this.onCancel.emit();
    }

    createNewFolder(): void {
        this.isNewFolder = true;
        const newFolder = {
            id: 'newFolderId',
            type: ListItemType.Folder,
            name: ''
        } as IListItem;

        this.dataSource.setData([newFolder, ...this.listItems]);
    }

    cancelNewFolder(): void {
        this.isNewFolder = false;
        this.dataSource.setData(this.listItems.filter(item => item.id !== 'newFolderId'));
    }

    saveNewFolder(event: InlineEditOnSaveEvent): void {
        this.duplicateCreativeStateService.createNewFolder(
            this.brandId,
            event.newValue,
            this.targetFolderId
        );
    }

    loadMoreData(): void {
        if (this.searchTerm) {
            this.duplicateCreativeStateService.search(this.searchTerm, this.page + 1);
            return;
        }
        this.duplicateCreativeStateService.loadPage(this.targetFolderId, this.page + 1, this.sort);
    }

    selectionChange(selection: RowEvent<IListItem>): void {
        const selected = selection.row;
        if (!selected || this.isPendingRequest) {
            return;
        }

        if (selected.type === 'Folder') {
            if (selected.id === this.targetFolderId) {
                return;
            }
            this.searchTerm = '';
            this.breadcrumbs.push(selected);
            this.loadCreativeSets(selected.id);
        } else {
            this.targetItemId = selected.id;
            this.targetExternalId = selected.externalId;
            this.targetCreativesetName = selected.name;
        }

        this.checkDuplicationDisabled();
    }

    navigateToFolder(id?: string, index?: number): void {
        if (id === 'search-results') {
            return;
        }

        if (this.targetFolderId === id) {
            return;
        }

        if (!id) {
            this.breadcrumbs = [];
            if (this.searchTerm) {
                this.searchTermChanged(this.searchTerm);
            } else {
                this.loadCreativeSets();
            }
            return;
        }
        this.breadcrumbs.splice(index!, this.breadcrumbs.length - 1);
        this.loadCreativeSets(id);
    }

    duplicateCreatives(): void {
        const selectedSizesIds = this.selectedSizes.map(size => size.id);
        const selectedVersionsIds = this.selectedVersions.map(version => version.id);

        if (this.duplicateToNew) {
            this.duplicateToFolder(selectedSizesIds, selectedVersionsIds);
        } else {
            this.duplicateToCreativeset(selectedSizesIds, selectedVersionsIds);
        }
    }

    @UIDebounce(300)
    searchTermChanged(newTerm: string): void {
        if (!clearWildcards(newTerm)) {
            this.duplicateCreativeStateService.clearSearch();
            this.breadcrumbs = [];
            this.loadCreativeSets();
            return;
        }
        if (!this.searchTerm) {
            this.page = 1;
            this.totalPages = 1;
            this.showLoadMore = false;
        }
        this.updateBreadcrumbs();
        this.duplicateCreativeStateService.search(newTerm, this.page, this.sort);
    }

    private displayNotification(response: IDuplicationResponse): void {
        const url = `brand/${response.brandId}/creativeset/${response.creativeSetId}`;
        this.uiNotificationService.open(
            `Creatives are being duplicated.<br>
    <a href="${url}" target="_blank" rel="noopener noreferrer">Go to target creative set</a>`,
            { type: 'success', placement: 'top', autoCloseDelay: 5000 }
        );
    }

    private displayErrorNotification(message: string): void {
        this.uiNotificationService.open(message, { type: 'error', placement: 'top' });
    }

    private loadCreativeSets(folderId?: string): void {
        this.updateBreadcrumbs();
        this.page = 1;
        this.totalPages = 1;
        this.targetFolderId = folderId;

        if (this.searchTerm) {
            this.duplicateCreativeStateService.search(this.searchTerm, this.page, this.sort);
            return;
        }

        this.duplicateCreativeStateService.loadPage(this.targetFolderId, this.page, this.sort);
    }

    private duplicateToFolder(selectedSizesIds: string[], selectedVersionsIds: string[]): void {
        this.duplicateCreativeStateService.duplicateToNew(
            this.creativesetId,
            selectedSizesIds,
            selectedVersionsIds,
            this.targetFolderId ?? ''
        );
    }

    private duplicateToCreativeset(selectedSizesIds: string[], selectedVersionsIds: string[]): void {
        if (!this.targetItemId || !this.targetExternalId) {
            throw new Error('Missing target for duplication');
        }
        this.duplicateCreativeStateService.duplicateToExisting(
            this.creativesetId,
            selectedSizesIds,
            selectedVersionsIds,
            this.targetExternalId
        );
    }

    private checkDuplicationDisabled(): void {
        if (this.duplicateToNew) {
            this.disableDuplication = !this.selectedSizes.length || !this.selectedVersions.length;
        } else {
            this.disableDuplication = !this.selectedSizes.length || !this.targetItemId;
        }
    }

    private setItemsFromResponse(response?: ICLVList): void {
        this.isNewFolder = false;
        this.updatePagination(response);
        this.currentItems = this.combineCurrentItems(response?.items ?? []);
        const newDataSource = this.createDataSource(this.currentItems);
        this.dataSource.setData(newDataSource);
    }

    private updateBreadcrumbs(): void {
        if (this.searchTerm) {
            this.displayBreadcrumbs = [
                { id: '', name: 'Creative sets' },
                { id: 'search-results', name: 'Search results' }
            ];
        } else {
            this.displayBreadcrumbs = [{ id: '', name: 'Creative sets' }, ...this.breadcrumbs];
        }
    }

    private updatePagination(response?: ICLVList): void {
        if (!response) {
            this.totalPages = 1;
            this.page = 1;
            this.showLoadMore = false;
            return;
        }
        this.totalPages = Math.ceil(response.totalItemsCount / response.currentPageSize);
        this.page = response.currentPage;
        this.showLoadMore = this.page < this.totalPages;
    }

    private combineCurrentItems(newItems: ICLVListItem[]): ICLVListItem[] {
        return this.page > 1 ? [...this.currentItems, ...newItems] : newItems;
    }

    private createDataSource(items: ICLVListItem[]): IListItem[] {
        const newDataSource: IListItem[] = [];

        for (const item of items) {
            const disabled = this.duplicateToNew
                ? item.type === 'CreativeSet'
                : item.id === this.creativesetDataService.creativeset.id;

            const formattedDate = item.createdAt ? new Date(item.createdAt).toLocaleDateString() : '';

            newDataSource.push({
                ...item,
                createdAt: formattedDate,
                disabled
            });
        }

        return newDataSource;
    }
}
