<studio-ui-section
    headline="Video"
    class="no-padding">
    <div class="setting">
        @if (elements$ | async; as elements) {
            <asset-property
                [context]="AssetPropertyContext.Replace"
                [showReplaceButton]="true"
                label="Content"
                [allowFeed]="true"
                [allowRemove]="false"
                [elements]="elements"
                [assetType]="ElementKind.Video"
                [mixed]="asset.isMixed"
                (feedSettingsChanged)="onFeedSettingsChanged($event)">
            </asset-property>
        }

        <div class="setting-row alternate">
            <div class="setting-label">Size mode</div>
            <div class="setting-value select col-1 wide video-fitting-options">
                <ui-select
                    [selected]="sizeMode.isMixed ? 'Mixed' : sizeMode.value"
                    [useTargetWidth]="true"
                    (selectedChange)="onSizeModeChange($event)">
                    @if (sizeMode.isMixed) {
                        <ui-option
                            [value]="'Mixed'"
                            [selected]="true">
                            Mixed
                        </ui-option>
                        <div class="option-divider"></div>
                    }
                    <ui-option [value]="VideoSizeMode.Fit">Fit</ui-option>
                    <ui-option [value]="VideoSizeMode.Crop">Crop</ui-option>
                </ui-select>
            </div>
        </div>
    </div>

    <div class="divider"></div>

    <div class="setting">
        @if (isEmployee$ | async) {
            <div class="setting-row reverse">
                <div class="setting-label">Streaming</div>
                <div class="setting-value">
                    <ui-toggle-switch
                        [disabled]="!isStreamingToggleEnabled"
                        [selected]="!!streaming.value?.enabled"
                        [interim]="streaming.isMixed"
                        [emitOnInputValueChange]="false"
                        (selectedChange)="onStreamingChange($event)">
                    </ui-toggle-switch>
                </div>
            </div>
        }
        <div
            class="setting-row reverse"
            [uiTooltip]="'Automatically starts the playback of the video'"
            [uiTooltipPosition]="'left'">
            <div class="setting-label">Autoplay</div>
            <div class="setting-value">
                <ui-toggle-switch
                    [interim]="autoplay.isMixed"
                    [selected]="!!autoplay.value"
                    [emitOnInputValueChange]="false"
                    (selectedChange)="onAutoplayChange($event)">
                </ui-toggle-switch>
            </div>
        </div>

        <div
            class="setting-row reverse"
            [uiTooltip]="'Stop the video playback when the creative stops'"
            [uiTooltipPosition]="'left'">
            <div class="setting-label">Stop with Creative</div>
            <div class="setting-value">
                <ui-toggle-switch
                    [interim]="stopWithCreative.isMixed"
                    [selected]="!!stopWithCreative.value"
                    [emitOnInputValueChange]="false"
                    (selectedChange)="onStopWithCreativeChange($event)">
                </ui-toggle-switch>
            </div>
        </div>

        <div class="setting-row alternate">
            <div class="setting-label">Start/End</div>
            <div class="setting-value col-2">
                <ui-number-input
                    data-test-id="start-time"
                    unitLabel="s"
                    placeholder="-"
                    [value]="startTime.value"
                    [btnUpDisabled]="
                        endTime.value === startTime.value || maxEndTime === startTime.value
                    "
                    [btnDownDisabled]="startTime.value === 0"
                    [max]="getMaxDurationInputValue()"
                    [min]="0"
                    [allowEmpty]="startTime.isMixed"
                    (valueChange)="onStartTimeChange($event)">
                </ui-number-input>
                <ui-number-input
                    data-test-id="end-time"
                    placeholder="-"
                    unitLabel="s"
                    [btnUpDisabled]="endTime.value === maxEndTime"
                    [btnDownDisabled]="endTime.value === startTime.value || !endTime.value"
                    [value]="endTime.value"
                    [min]="startTime.value"
                    [max]="getMaxDurationInputValue()"
                    [allowEmpty]="true"
                    (valueChange)="onEndTimeChange($event)">
                </ui-number-input>
            </div>
        </div>

        <div class="setting-row reverse">
            <div class="setting-label">Loop</div>
            <div class="setting-value">
                <ui-toggle-switch
                    [interim]="loop.isMixed"
                    [selected]="!!loop.value"
                    [emitOnInputValueChange]="false"
                    (selectedChange)="onLoopChange($event)">
                </ui-toggle-switch>
            </div>
        </div>

        <div
            class="setting-row reverse"
            [uiTooltip]="'Restarts the playback of the video at the Start Time when the creative loops'"
            [uiTooltipPosition]="'left'">
            <div class="setting-label">Restart with Creative</div>
            <div class="setting-value">
                <ui-toggle-switch
                    [interim]="restartWithCreative.isMixed"
                    [selected]="!!restartWithCreative.value"
                    [emitOnInputValueChange]="false"
                    (selectedChange)="onRestartWithCreativeChange($event)">
                </ui-toggle-switch>
            </div>
        </div>

        <div class="setting-row long-label">
            <div class="setting-label margin-right">Playback speed</div>
            <div class="setting-value select">
                <ui-select
                    [selected]="playbackRate.isMixed ? 'Mixed' : playbackRate.value"
                    [useTargetWidth]="true"
                    (selectedChange)="onPlaybackRateChange($event)">
                    @if (playbackRate.isMixed) {
                        <ui-option
                            [value]="'Mixed'"
                            [selected]="true">
                            Mixed
                        </ui-option>
                        <div class="option-divider"></div>
                    }
                    <ui-option [value]="0.25">0.25x</ui-option>
                    <ui-option [value]="0.5">0.50x</ui-option>
                    <ui-option [value]="0.75">0.75x</ui-option>
                    <ui-option [value]="1">1x</ui-option>
                    <ui-option [value]="1.25">1.25x</ui-option>
                    <ui-option [value]="1.5">1.50x</ui-option>
                    <ui-option [value]="1.75">1.75x</ui-option>
                    <ui-option [value]="2">2x</ui-option>
                </ui-select>
            </div>
        </div>

        <div
            data-test-id="volume"
            class="setting-row wide"
            [uiTooltip]="'Audio will only be applied for MP4 exports'"
            [uiTooltipPosition]="'left'">
            <div class="setting-label">Volume</div>
            <div class="setting-value col-2 wide">
                <div class="property-input slider">
                    <studio-ui-slider
                        id="volume-slider"
                        [value]="volume.value ? (volume.value | fractionToPercentage) : 0"
                        (valueChange)="onVolumeChange($event, ElementChangeType.Skip)"
                        (mouseUp)="onVolumeChange($event, ElementChangeType.Force)"
                        [config]="{ min: 0, max: 100 }">
                    </studio-ui-slider>
                </div>
                <form>
                    <div class="property-input">
                        <ui-number-input
                            id="volume-input"
                            unitLabel="%"
                            [value]="volume.value ? (volume.value | fractionToPercentage) : undefined"
                            [keyboardEmit]="true"
                            [arrowButtons]="!volume.isMixed"
                            [placeholder]="'-'"
                            [allowEmpty]="true"
                            [min]="0"
                            [max]="100"
                            [disableUndo]="true"
                            (valueChange)="onVolumeChange($event, ElementChangeType.Burst)"
                            (undo)="undo()"
                            (redo)="redo()">
                        </ui-number-input>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="divider"></div>

    <div class="setting">
        <div class="setting-row reverse">
            <div class="setting-label">Play/Pause button</div>
            <div class="setting-value">
                <ui-toggle-switch
                    [interim]="playbackButton.isMixed"
                    [selected]="(playbackButton.value && playbackButton.value.enabled) || false"
                    [emitOnInputValueChange]="false"
                    (selectedChange)="onPlaybackButtonToggle($event)">
                </ui-toggle-switch>
            </div>
        </div>

        @if (playbackButton.value && playbackButton.value.enabled) {
            <div class="setting-row wide">
                <div class="setting-label">Size</div>
                <div class="setting-value col-2 wide">
                    <div class="property-input slider">
                        <studio-ui-slider
                            id="radius-slider"
                            [value]="playbackButton.value.size || 0"
                            (valueChange)="onPlaybackButtonSizeChange($event, ElementChangeType.Skip)"
                            (mouseUp)="
                                onPlaybackButtonSizeChange(
                                    playbackButton.value.size,
                                    ElementChangeType.Force
                                )
                            "
                            [config]="{ min: 0, accuracy: 1, max: 100 }">
                        </studio-ui-slider>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="radius-input"
                            [value]="playbackButton.value.size"
                            (valueChange)="onPlaybackButtonSizeChange($event, ElementChangeType.Burst)"
                            [keyboardEmit]="true"
                            [min]="0"
                            [max]="100"
                            [step]="1"
                            [format]="'1.0-2'"
                            maxLabel="Full"
                            [arrowButtons]="!playbackButton.isMixed"
                            [placeholder]="'-'"
                            [allowEmpty]="false"
                            [disableUndo]="true">
                        </ui-number-input>
                    </div>
                </div>
            </div>

            <div class="setting-value col-3 input">
                <div class="setting-label">Color</div>

                <color-button
                    [color]="playbackButton.value.color"
                    [colorMixed]="playbackButton.isMixed"
                    (click)="toggleColorPicker()"
                    data-test-id="color-button">
                </color-button>
            </div>
            <div
                class="color-picker"
                data-test-id="color-picker">
                @if ('playbackButtonColor' | picker | async) {
                    <section-expand
                        id="playbackButtonColor"
                        arrowPosition="33px"
                        [showBackground]="true"
                        [removeContentWhenCollapsed]="false"
                        [expanded]="true">
                        <color-section
                            name="playbackButtonColor"
                            [allowGradient]="false"
                            [preventCloseElements]="['#playbackButtonColor']"
                            [color]="playbackButton.value.color"
                            (onColorChange)="
                                onPlaybackButtonColorChange($event, ElementChangeType.Skip)
                            "
                            (onColorChanged)="onPlaybackButtonColorChange($event)"
                            (preview)="onPlaybackButtonColorPreview($event)"
                            (previewStop)="onPlaybackButtonColorChange($event)">
                        </color-section>
                    </section-expand>
                }
            </div>
        }
    </div>
</studio-ui-section>
