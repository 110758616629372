import { Logger } from '@bannerflow/sentinel-logger';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BrandService } from '@studio/common/brand';
import { firstValueFrom, Observable, Subscriber, TeardownLogic } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../user/state/user.service';

export abstract class SignalRHub<HubEvents extends Record<string, unknown>> {
    protected readonly logger = new Logger('SignalRHubService');
    protected connection: HubConnection;
    private brandId = '';
    constructor(
        protected userService: UserService,
        protected signalRHubUrl: string,
        protected brandService: BrandService,
        protected authService: AuthService
    ) {
        this.initializeConnection();
    }

    private createConnection(): void {
        this.logger.verbose(`building connection to ${this.signalRHubUrl}`);

        const headers = this.prepareHeaders();

        this.connection = new HubConnectionBuilder()
            .withUrl(this.signalRHubUrl, {
                accessTokenFactory: () =>
                    firstValueFrom(
                        this.authService.getAccessToken().pipe(
                            filter(val => !!val),
                            map(val => {
                                const token = val ?? '';
                                return token;
                            })
                        )
                    ),
                headers
            })
            .withAutomaticReconnect()
            .build();
    }

    private prepareHeaders(): Record<string, string> {
        return {
            'bf-brand-id': this.brandId ?? ''
        };
    }

    async initializeConnection(): Promise<void> {
        const brandId = await firstValueFrom(this.brandService.brandId$.pipe(take(1)));
        this.brandId = brandId;
        this.createConnection();

        this.onConnectionReady();
    }

    protected abstract onConnectionReady();

    startConnection(): Promise<void> {
        this.logger.verbose('starting connection');
        if (this.connection.state === 'Connected') {
            this.logger.verbose('connection already started');
            return Promise.resolve();
        }

        return this.connection
            .start()
            .then(() => {
                this.logger.verbose('connection successful');
            })
            .catch(err => {
                this.logger.error(err);
            });
    }

    protected on<
        EventName extends Extract<keyof HubEvents, string>,
        EventData extends HubEvents[EventName]
    >(eventName: EventName): Observable<EventData> {
        let subscriber: Subscriber<EventData>;
        let currentValue: EventData;
        this.connection.on(eventName, (eventData: EventData) => {
            currentValue = eventData;
            subscriber.next(eventData);
        });

        this.connection.off(eventName, () => {
            subscriber.complete();
        });

        this.logger.debug(`listening on: ${eventName}`);

        return new Observable<EventData>((sub): TeardownLogic => {
            subscriber = sub;
            if (currentValue) {
                subscriber.next(currentValue);
            }
        });
    }

    get connectionId(): string | undefined {
        return this.connection?.connectionId || undefined;
    }
}
